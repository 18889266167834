import { createMachine, assign, actions, send } from 'xstate';

import { getProducts } from './data';
import { machine as wizard } from './wizard';

export const machine = createMachine({
  id: 'catalogue.list',
  initial: 'loading',

  context: {
    activeLine: '',
    isReportOpen: false,
    report: {
      id: 'report-1',
      result: new Set(),
      items: {},
    },
    result: new Set(),
    items: {},
  },

  states: {
    // @TODO consider moving to substate of ready
    editingLine: {
      entry: assign({
        activeLine: (ctx, { id }) => id,
      }),
      exit: assign({
        activeLine: '',
      }),

      on: {
        // @TODO use choose to support switching between activeLine
        // when the event.id is different than the activeLine then we want
        // to stay in editingLine but just with a different line id
        'LINE.TOGGLE_EDIT': {
          target: 'ready',
        },
        // @NOTE looks the same as above but could have implications when to
        // save stuff
        'LINE.CANCEL_EDIT': {
          target: 'ready',
        },

        'REPORT.CLOSE': {
          // target: 'ready',
          actions: [
            send('LINE.CANCEL_EDIT'),
            assign({
              isReportOpen: false,
            }),
          ],
        },
      },
    },

    // @TODO remember step when wizard was interrupted
    wizardPaused: {
      // For now redirect to ready so the report can be opened
      always: 'ready',
    },

    sendingReport: {
      initial: 'started',
      states: {
        started: {
          // Simulate succesfull send
          after: {
            3000: { target: 'send' },
          },
        },

        send: {
          type: 'final',
        },

        failed: {},
      },

      onDone: {
        actions: send('NOTIFY', { delay: 2000 }),
      },
      on: {
        NOTIFY: 'ready',
      },
    },

    reportSummary: {
      on: {
        'WIZARD.END': {
          target: 'sendingReport',
          // actions: actions.log('Show a message that the report was send'),
        },
        'WIZARD.PREVIOUS': {
          target: 'clientDetails',
        },
        // 'WIZARD.PAUSE': {
        //   target: 'wizardPaused',
        // },
      },
    },

    clientDetails: {
      on: {
        'WIZARD.NEXT': {
          target: 'reportSummary',
        },

        'WIZARD.PREVIOUS': {
          target: 'reportOverview',
        },

        // // @TODO wizard can only be paused when the modal is dismissed via another
        // // path that isn't next/prev
        // 'WIZARD.PAUSE': {
        //   target: 'wizardPaused',
        // },
      },
    },

    reportOverview: {
      // @TODO fix this with action/state or something
      entry: assign({
        isReportOpen: false,
      }),

      on: {
        'WIZARD.NEXT': {
          target: 'clientDetails',
        },

        'WIZARD.PREVIOUS': {
          target: 'ready',
          actions: send('REPORT.OPEN'),
        },

        // 'WIZARD.PAUSE': {
        //   target: 'wizardPaused',
        // },
      },
    },

    ready: {
      on: {
        'LINE.TOGGLE_EDIT': {
          target: 'editingLine',
        },

        'WIZARD.START': {
          target: 'reportOverview',
        },

        // SHould report modal be separate machine/state?
        'REPORT.CLOSE': {
          actions: assign({
            isReportOpen: false,
          }),
        },
        'REPORT.OPEN': {
          actions: assign({
            isReportOpen: true,
          }),
        },
        'REPORT.ADD_LINE': {
          actions: assign({
            report: ({ report, items }, event) => {
              const nextReport = { ...report };
              const item = items[event.id];

              const dummyLineContent = [
                {
                  label: 'Aantal',
                  value: 2,
                  contentType: 'amount',
                  formattedValue: '2',
                },
                {
                  label: 'Afmetingen',
                  value: [
                    { label: 'breedte', unit: 'cm', value: 100 },
                    { label: 'hoogte', unit: 'cm', value: 200 },
                  ],
                  formattedValue: '200 cm x 100 cm',
                  contentType: 'dimensions',
                },
                {
                  label: 'Doek',
                  value: 'zwart-9005',
                  contentType: 'color',
                  formattedValue: 'Zwart 9005',
                },
                {
                  label: 'Frame',
                  value: 'zwart-9005',
                  contentType: 'color',
                  formattedValue: 'Zwart 9005',
                },
              ];

              nextReport.result.add(event.id);
              /* items = catalogue items */
              nextReport.items[event.id] = {
                id: event.id,
                // for now assume content type is a product
                title: item.title,
                content: item,
                contentType: event.contentType,

                properties: dummyLineContent,
              };

              return nextReport;
            },
          }),
        },
      },
    },
    loading: {
      invoke: {
        id: 'products',
        src: getProducts,
        onDone: {
          target: 'ready',
          actions: assign({
            result: ({ result }, { data }) => {
              for (const item of data) {
                result.add(item.id);
              }

              return result;
            },
            items: ({ items }, { data }) => {
              const nextItems = data.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
              }, {});

              return {
                ...items,
                ...nextItems,
              };
            },
          }),
        },
      },
    },
  },
});
