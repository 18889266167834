import React, { useEffect, useMemo } from 'react';
import { Modal } from '@vitrona/ui';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import Form, {
  withTheme,
  UiSchema,
  WidgetProps,
  Widget,
  FieldProps,
  FieldTemplateProps,
  utils,
  ObjectFieldTemplateProps,
  FormProps,
  ISubmitEvent,
} from '@rjsf/core';
import styled from '@emotion/styled';
import { JSONSchema7 } from 'json-schema';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { Page, Block, BlockInner } from '@vitrona/ui';
import { fetchProductAsync } from '@vitrona/state/catalogue';
import { ChoiceOption, Product, ToggleOption } from '@vitrona/api/catalogue';

function getCatalogueItems(state) {
  return state.getIn(['catalogue', 'items']);
}

const selectProduct = createSelector(
  getCatalogueItems,
  // @TODO get product from active offer line
  () => '88d48fa0-dd4d-40c4-bfd7-5fff019a2b75',
  (catalogue, productId): Product => catalogue.get(productId, Product.empty())
);

const selectOptions = createSelector(
  getCatalogueItems,
  selectProduct,
  (catalogue, product) =>
    product.properties.options
      .map((optionId) => catalogue.get(optionId))
      .filter(Boolean)
);

const selectVariants = createSelector(
  getCatalogueItems,
  selectProduct,
  (catalogue, product) =>
    product.properties.variants
      .map((variantId) => catalogue.get(variantId))
      .filter(Boolean)
);

const selectBooleanOptions = createSelector(
  selectOptions,
  (options = []): ToggleOption[] =>
    options.filter((option) => option.type === 'toggle')
);

const selectBooleanOptionDefinitions = createSelector(
  selectBooleanOptions,
  (options) =>
    options.map(({ id, properties }) => ({
      label: properties.title,
      id,
      contentType: 'boolean',
      isRequired: properties.isRequired,
      default: properties.initialValue,
      // uiSchema: {},
    }))
);

const selectBooleanOptionSchemas = createSelector(
  selectBooleanOptionDefinitions,
  (definitions) => {
    const properties = definitions.reduce((acc, def) => {
      const optionSchema = {
        title: def.label,
        type: def.contentType,
        default: def.default,
      } as JSONSchema7;

      acc[def.id] = optionSchema;

      return acc;
    }, {});

    return properties;
  }
);

function selectChoiceItemsFactory(id: string) {
  const selectItemIds = createSelector(
    getCatalogueItems,
    (catalogue) =>
      catalogue.get(id, { properties: { items: [] } }).properties.items
  );

  return createSelector(
    selectItemIds,
    getCatalogueItems,
    // prettier-ignore
    (ids, catalogue) => ids
      .map((id) => catalogue.get(id))
      .filter(Boolean)
  );
}

const selectChoiceOptions = createSelector(
  selectOptions,
  (options = []): ChoiceOption[] =>
    options.filter((option) => option.type === 'choice')
);

const selectProductSchema = createSelector(selectProduct, (product) => ({
  quantity: {
    title: 'Aantal',
    type: 'number',
    default: 1,
    minimum: 1,
  } as JSONSchema7,
}));

const selectChoiceOptionDefinitions = createSelector(
  selectChoiceOptions,
  (options) =>
    options.map(({ id, properties }) => ({
      label: properties.title,
      id,
      contentType: 'string',
      isRequired: properties.isRequired,
      // select doesn't like null
      default:
        properties.initialValue === null ? void 0 : properties.initialValue,
      uiSchema: {
        'ui:placeholder': `--Kies ${properties.title.toLowerCase()}--`,
        'ui:widget': 'select',
      },
    }))
);

const selectVariantDefinition = createSelector(selectProduct, (product) => ({
  label: 'Afmetingen',
  id: 'productVariant',
  contentType: 'string',
  isRequired: true,
  // select doesn't like null
  default: void 0,
  uiSchema: {
    'ui:placeholder': `--Kies afmeting--`,
    'ui:widget': 'select',
    'ui:help': product.properties.dimensions
      .map(({ label }) => label)
      .join(' x '),
  },
}));

function formatDimensionLabel(dimensions: { label: string; value: string }[]) {
  const values = `${dimensions.map((d) => d.value).join(' x  ')}`;
  return `${values}`;
}

const selectVariantItemSchemas = createSelector(
  selectVariantDefinition,
  selectVariants,
  (definition, options) => {
    const definitions = [definition];

    const properties = definitions.reduce((acc, def) => {
      const optionSchema = {
        title: def.label,
        type: def.contentType,
        default: def.default,

        anyOf: options.map((option) => ({
          type: 'string',
          enum: [option.id],
          title: formatDimensionLabel(option.properties.dimensions),
        })),
      } as JSONSchema7;

      acc[def.id] = optionSchema;

      return acc;
    }, {});

    return properties;
  }
);

const selectChoiceItems = createSelector(selectChoiceOptions, (options = []) =>
  options.reduce((acc, option) => {
    acc[option.id] = selectChoiceItemsFactory(option.id);

    return acc;
  }, {})
);

const selectChoiceOptionSchemas = createSelector(
  selectChoiceOptionDefinitions,
  (state) => state,
  selectChoiceItems,
  (definitions, state, itemSelectors) => {
    const properties = definitions.reduce((acc, def) => {
      const selector = itemSelectors[def.id] ? itemSelectors[def.id] : () => [];

      const optionSchema = {
        title: def.label,
        type: def.contentType,
        default: def.default,

        anyOf: selector(state).map((option) => ({
          type: 'string',
          enum: [option.id],
          title: option.properties.title,
        })),
      } as JSONSchema7;

      acc[def.id] = optionSchema;

      return acc;
    }, {});

    return properties;
  }
);

const selectProductOptionDefinitions = createSelector(
  selectBooleanOptionDefinitions,
  selectChoiceOptionDefinitions,
  (...definitions) => {
    return definitions.flatMap((d) => d);
  }
);

const selectFormData = createSelector(
  // prettier-ignore
  () => null,
  // this should come from the offerLine
  () => ({
    quantity: 2,
  })
);

const selectRequiredOptions = createSelector(
  selectBooleanOptionDefinitions,
  selectChoiceOptionDefinitions,
  selectVariantDefinition,
  (...definitions) =>
    definitions
      .flatMap((list) => list)
      .filter((def) => def.isRequired)
      .map((def) => def.id)
);

const selectSchemaComposed = createSelector(
  selectProductSchema,
  selectBooleanOptionSchemas,
  selectChoiceOptionSchemas,
  selectVariantItemSchemas,
  selectRequiredOptions,
  (p1, p2, p3, p4, required) => {
    const schema: JSONSchema7 = {
      type: 'object',
      properties: {
        ...p1,
        ...p4,
        ...p2,
        ...p3,
      },
      required,
    };

    return schema;
  }
);

const selectUiSchema = createSelector(
  selectProductOptionDefinitions,
  selectVariantDefinition,
  (definitions, variant) => {
    return definitions.concat(variant).reduce(
      (acc, { id, uiSchema }) => {
        acc[id] = uiSchema;

        return acc;
      },
      {
        dimensions: {
          // 'ui:field': 'dimension',
          variant: {
            'ui:readonly': true,
          },
        },
      }
    );
  }
);

// select productVariant when dimensions changed
function DimensionField(props: FieldProps) {
  console.log('custom widget', props);

  return null;
}

export function TestForm() {
  const formData = useSelector(selectFormData);
  const schema = useSelector(selectSchemaComposed);
  const uiSchema = useSelector(selectUiSchema);

  const p = useSelector(selectProduct);

  console.log('product', p);

  const dispatch = useDispatch();

  useEffect(() => {
    // create a form with product id

    setTimeout(() => {
      import('@vitrona/api/catalogue').then(({ product }) =>
        dispatch(
          fetchProductAsync.done({
            params: { id: '88d48fa0-dd4d-40c4-bfd7-5fff019a2b75' },
            result: product,
          })
        )
      );
    }, 1000);
  }, []);

  // console.log('schema: %o\ndata: %o\nuiSchema: %o', schema, formData, uiSchema);

  return (
    <Page title="Test formulier">
      <Block className="ion-padding-top">
        <BlockInner>
          <Form
            formData={formData}
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={console.log}
            onChange={console.info}
            fields={{ dimension: DimensionField }}
          />

          <pre style={{ fontSize: '1.5ex' }}>
            <code>
              {JSON.stringify({ uiSchema, schema, formData }, null, 2)}
            </code>
          </pre>
        </BlockInner>
      </Block>
    </Page>
  );
}
