import {
  spawn,
  createMachine,
  assign,
  actions,
  send,
  sendParent,
} from 'xstate';

const offerEditorMachine = createMachine({
  id: 'offerEditor',
  initial: 'idle',
  context: {
    lineId: null,
    productId: null,
    prevLine: {
      lineId: null,
      productId: null,
    },
  },
  states: {
    idle: {
      on: {
        'LINE.TOGGLE_EDIT': {
          target: 'editingLine',
          actions: [
            assign({
              lineId: (_ctx, event) => event.id,
              productId: (_ctx, event) => event.productId,
            }),
            sendParent((_ctx, event) => ({
              type: 'LINE.EDIT',
              id: event.id,
              productId: event.productId,
            })),
          ],
        },
      },
    },
    editingLine: {
      exit: [
        assign((ctx) => ({
          ...ctx,
          prevLine: { lineId: ctx.lineId, productId: ctx.productId },
          lineId: null,
          productId: null,
        })),
      ],
      on: {
        'LINE.CANCEL_EDIT': {
          target: 'idle',
          actions: [sendParent('LINE.CANCEL_EDIT')],
        },
        'LINE.SUBMIT_SUCCESS': {
          target: 'idle',
          actions: [
            sendParent((ctx, event) => ({
              type: 'LINE.SUBMIT_SUCCESS',
              value: event.value,
              // We can't use ctx.lineId because the exit assign already happend
              id: ctx.prevLine.lineId,
              productId: ctx.prevLine.lineId,
            })),
          ],
        },
        'LINE.SUBMIT_ERROR': {
          actions: actions.log((c, e) => [c, e], '@TODO deal with error state'),
        },
      },
    },
  },
  on: {
    'OFFER.CLOSE': {
      actions: sendParent('OFFER.CLOSE'),
    },
    'LINE.REMOVE': {
      actions: sendParent((_ctx, event) => ({
        type: 'LINE.REMOVE',
        id: event.id,
      })),
    },
  },
});

export function createOfferMachine() {
  return createMachine({
    id: 'offerMachine',
    initial: 'idle',
    context: {
      offerId: '',
      editor: null,
    },
    states: {
      idle: {
        on: {
          'OFFER.CREATE': 'creating',
        },
      },

      creating: {
        entry: 'createOffer',
        on: {
          'OFFER.CHANGED': {
            actions: assign({
              offerId: (ctx, event) => event.offerId,
            }),
            target: 'basicClientDetails',
          },
        },
      },

      basicClientDetails: {
        on: {
          SUBMIT: {
            target: 'saving',
          },
          'OFFER.ABORT': {
            actions: [
              'abortOffer',
              assign({
                offerId: () => '',
              }),
            ],
            target: 'idle',
          },
        },
      },

      saving: {
        entry: 'saveChanges',
        after: {
          300: 'offerReady',
        },
      },

      offerReady: {
        initial: 'idle',
        states: {
          idle: {},
          editor: {
            on: {
              'LINE.CANCEL_EDIT': {
                actions: 'cancelLinEditing',
              },
              'LINE.EDIT': {
                actions: 'editingLine',
              },
              'LINE.SUBMIT_SUCCESS': {
                actions: 'saveLine',
              },
            },
          },
        },
        on: {
          'OFFER.OPEN': {
            actions: assign({
              editor: ({ editor }) =>
                editor || spawn(offerEditorMachine, 'editor'),
            }),
            target: '.editor',
          },
          'OFFER.CLOSE': '.idle',

          'OFFER.ADD_LINE': {
            actions: ['createOfferLine'],
          },
          'LINE.REMOVE': {
            actions: ['removeOfferLine'],
          },
        },
      },
    },
  });
}
