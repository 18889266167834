import React from 'react';
import styled from '@emotion/styled';

export const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  position: relative;
`;
