import React from 'react';
import styled from '@emotion/styled';

export const BlockInner = styled.div`
  max-width: ${1072 + 32}px;
  width: 100%;
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
  hyphens: auto;
`;
