import styled from '@emotion/styled';
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonRouterLink,
} from '@ionic/react';
import React from 'react';

const StyledCard = styled(IonCard)`
  margin-left: 0;
  margin-right: 0;
  box-shadow: none;
  border: 1px solid #dedede;
  border-radius: 2px;

  & + * {
    margin-top: var(--spacing-3);
  }
`;

interface IProductCard {
  title: string;
  productKind: string;
  renderActions?: () => React.ReactNode;
  subtitle?: React.ReactNode;
}

export function ProductCard({
  title,
  productKind,
  renderActions = () => null,
  subtitle = null,
}: IProductCard) {
  return (
    <StyledCard>
      <IonCardHeader>
        <IonCardSubtitle
          style={{ fontSize: '.8rem', textTransform: 'lowercase' }}
        >
          {productKind}
        </IonCardSubtitle>
        <IonCardTitle>
          <IonRouterLink color="secondary" href={'/catalogus/knikarmscherm'}>
            {title}
          </IonRouterLink>
        </IonCardTitle>

        {subtitle && <IonCardSubtitle>{subtitle}</IonCardSubtitle>}
      </IonCardHeader>

      {renderActions()}
    </StyledCard>
  );
}

export default ProductCard;
