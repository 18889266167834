import { actionCreatorFactory } from 'typescript-fsa';
import { IAPIProductKind, IAPIProduct } from '@vitrona/api/catalogue';
import { IRoute, IMatch } from './types';

const actionCreator = actionCreatorFactory('catalogue');

export const fetchProducts = actionCreator<{ route: IRoute; match: IMatch }>(
  'FETCH_PRODUCTS'
);
export const fetchProductsAsync = actionCreator.async<
  { route: IRoute; match: IMatch },
  IAPIProduct[]
>('FETCH_PRODUCTS');

export const fetchProductKinds = actionCreator('FETCH_PRODUCT_KINDS');
export const fetchProductKindsAsync = actionCreator.async<
  void,
  IAPIProductKind[]
>('FETCH_PRODUCT_KINDS');

export const fetchProduct = actionCreator<{ id: string }>('FETCH_PRODUCT');
export const fetchProductAsync = actionCreator.async<
  { id: string },
  IAPIProduct
>('FETCH_PRODUCT');
