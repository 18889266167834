import { Settings, SettingsOperator } from '@vitrona/settings';

import baseSettings from './base';

// @TODO fix typings for require
const envSettings: SettingsOperator =
  require(`./${process.env.NX_STAGE}`).default || baseSettings;
const settings = new Settings().pipe(envSettings);

export default settings.toJS();
