import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonModal,
  IonNote,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
  folderOpenOutline,
  fileTrayFullOutline,
  closeCircleOutline as closeIcon,
} from 'ionicons/icons';
import styled from '@emotion/styled';

// {/* <IonButton onClick={() => setShowModal(true)}>Show Modal</IonButton> */}

export function useReportModal(
  { isOpen = false }: { isOpen: boolean } = { isOpen: false }
) {
  const [showModal, setShowModal] = React.useState<boolean>(isOpen);

  return [showModal, setShowModal];
}

// @TODO move to own module
export function LegacyModal({
  isOpen,
  onDidDismiss = () => void 0,
  children,
  modalRef,
  ...rest
}: {
  isOpen: boolean;
  onDidDismiss: () => void;
  children: React.ReactNode;
}) {
  return (
    <IonModal
      ref={modalRef}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      {...rest}
    >
      {children}
    </IonModal>
  );
}

export function ReportEditor({
  isOpen = true,
  onDidDismiss,
  children = null,
  presentingElement,
  modalRef,
  ...rest
}: {
  isOpen?: boolean;
  onDidDismiss: () => void;
  children?: React.ReactNode;
}) {
  return (
    <LegacyModal
      modalRef={modalRef}
      presentingElement={presentingElement}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      {...rest}
    >
      <IonPage>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Offerte</IonTitle>

            <IonButtons slot="secondary">
              <IonButton onClick={onDidDismiss}>
                <IonIcon slot="end" icon={closeIcon} />
                Sluiten
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {children}
      </IonPage>
    </LegacyModal>
  );
}

// @TODO move
export function ReportOverview({
  isOpen = true,
  onDidDismiss,
  children = null,
  modalRef,
  presentingElement,
}: {
  isOpen?: boolean;
  onDidDismiss: () => void;
  children?: React.ReactNode;
}) {
  return (
    <LegacyModal
      modalRef={modalRef}
      presentingElement={presentingElement}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <IonPage>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Offerte - overzicht</IonTitle>

            <IonButtons slot="secondary">
              <IonButton onClick={onDidDismiss}>
                <IonIcon slot="end" icon={closeIcon} />
                Sluiten
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {children}
      </IonPage>
    </LegacyModal>
  );
}
