import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';

import * as catalogue from '@vitrona/state/catalogue';
import * as offer from '@vitrona/state/offer';

import { DefaultEpic } from './types';

const epics = combineEpics(
  ...Object.values({
    ...catalogue.epics,
    ...offer.epics,
  })
);

export default function setupEpics() {
  /*
 Uncaught errors can bubble up to the root epic and cause the entire stream to terminate. As a consequence, epics registered in the middleware will no longer run in your application. To alleviate this issue, you can add a global error handler to the root epic that catches uncaught errors and resubscribes to the source stream.

 Source: Adding global error handler, https://redux-observable.js.org/docs/basics/SettingUpTheMiddleware.html
 */
  // @TODO fix type for rootEpic VIT-54
  const rootEpic: DefaultEpic = (action$, store$, dependencies) =>
    epics(action$, store$, dependencies).pipe(
      catchError((error, source) => {
        console.error(error);
        return source;
      })
    );

  const epicMiddleware = createEpicMiddleware();

  return {
    epicMiddleware,
    runEpics() {
      return epicMiddleware.run(rootEpic);
    },
  };
}
