import {
  IonButton,
  IonButtons,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import {
  readerOutline as reportIcon,
  pencil as editIcon,
  add as addIcon,
} from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';

import {
  useSelectProductKind,
  useSelectProductsByKind,
} from '@vitrona/state/catalogue';
import { Page } from '@vitrona/ui';
import { Block, BlockInner } from '@vitrona/ui';
import { ProductCard } from '@vitrona/ui/catalogue';
import {
  abortOffer,
  cancelOfferLineEdit,
  createOffer,
  createOfferLine,
  editOfferLine,
  removeOfferLine,
  saveOffer,
  saveOfferLine,
} from '@vitrona/state/offer';
import { useMachine } from '@xstate/react';
import { createOfferMachine } from './machines';
import { BasicClientDetails } from './modals';
import { OfferEditor } from './OfferEditor';

interface IBasicClientData {
  email: string;
  firstName?: string;
  lastNamePrefix?: string;
  lastName?: string;
}

const Subtitle = styled.div`
  > * + * {
    margin-left: var(--spacing);
  }
`;

/* Temporary */
function send(a: string, b?: unknown) {}

const Input = styled(IonInput)`
  border: 1px solid var(--ion-border-color, #ddd);
`;

function SearchField() {
  return <Input type="search" placeholder="Zoeken"></Input>;
}
/* End temporary */

export function ProductListPage() {
  const { id } = useParams<{ id: string }>();
  const productKind = useSelectProductKind({ id });
  const products = useSelectProductsByKind({ id });

  const dispatch = useDispatch();
  const activeOffer =
    useSelector((state) => state.getIn(['offers', 'activeOffer'], '')) || '';

  const [state, send] = useMachine(() => createOfferMachine(), {
    devTools: true,
    actions: {
      createOffer: () => dispatch(createOffer()),
      abortOffer: () => dispatch(abortOffer({ id: activeOffer })),
      saveChanges: (_ctx, event) => {
        dispatch(saveOffer({ id: activeOffer, ...event.value }));
      },
      createOfferLine: (_ctx, event) =>
        dispatch(
          createOfferLine({
            offerId: activeOffer,
            productId: event.id,
          })
        ),
      editingLine: (_ctx, { id, productId }) =>
        dispatch(editOfferLine({ id, productId })),
      cancelLinEditing: () => dispatch(cancelOfferLineEdit()),
      saveLine: (_ctx, event) =>
        dispatch(
          saveOfferLine({
            id: event.id,
            productId: event.productId,
            offerId: activeOffer,
            value: event.value.formData,
          })
        ),
      removeOfferLine: (_ctx, event) =>
        dispatch(
          removeOfferLine({
            id: event.id,
            offerId: activeOffer,
          })
        ),
    },
  });

  useEffect(() => {
    send('OFFER.CHANGED', { offerId: activeOffer });
  }, [send, activeOffer]);

  /* used for debugging */
  // useEffect(() => {
  //   const id = setTimeout(() => {
  //     send('OFFER.CREATE');
  //   }, 400);

  //   return () => {
  //     clearTimeout(id);
  //   };
  // });

  const pageActions = (
    <IonButtons slot="secondary">
      <IonButton
        disabled={!state.matches('idle') /* or created */}
        fill="solid"
        onClick={() => {
          send('OFFER.CREATE');
        }}
      >
        <IonIcon slot="end" icon={addIcon} />
        Nieuwe offerte
      </IonButton>

      <IonButton
        disabled={state.matches('idle')}
        fill="solid"
        onClick={() => {
          send('OFFER.OPEN');
        }}
      >
        <IonIcon slot="end" icon={reportIcon} />
        Offerte tonen
      </IonButton>
    </IonButtons>
  );

  return (
    <Page
      title={productKind.title}
      renderHeaderToolbarSecondary={() => pageActions}
    >
      {(state.matches('basicClientDetails') || state.matches('saving')) && (
        <BasicClientDetails
          isOpen={true}
          onAbort={() => send('OFFER.ABORT')}
          onSubmit={(value: IBasicClientData) => send('SUBMIT', { value })}
        ></BasicClientDetails>
      )}

      {state.matches('offerReady.editor') && (
        <OfferEditor
          offerId={activeOffer}
          offerRef={state.context.editor}
          isOpen={true}
        />
      )}

      <IonContent>
        <Block>
          <BlockInner>
            <IonGrid>
              <IonRow>
                <IonCol size="9">
                  <SearchField />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="9">
                  {products.map((p) => (
                    <ProductCard
                      key={p.id}
                      title={p.properties.title}
                      productKind={productKind.title}
                      subtitle={
                        <Subtitle>
                          {[
                            ['Merk', 'brand'],
                            ['Model', 'model'],
                            ['Type', 'type'],
                          ]
                            .filter(([_, key]) => p.properties[key])
                            .map(([label, key]) => (
                              <span key={`${id}-${key}`}>
                                <strong>{label}</strong> {p.properties[key]}
                              </span>
                            ))}
                        </Subtitle>
                      }
                      renderActions={() => (
                        <IonCardContent>
                          <IonButton
                            onClick={() => {
                              send('OFFER.OPEN');

                              send('OFFER.ADD_LINE', {
                                id: p.id,
                                contentType: 'product',
                              });
                            }}
                            color="dark"
                            fill="outline"
                            size="small"
                          >
                            Toevoegen
                          </IonButton>
                          {/* @TODO use color that doesn't look disabled */}
                          {/* @TODO Currently the detail page is a low priority */}
                          {/* <IonButton color="medium" fill="outline" size="small">
                            Details
                          </IonButton> */}
                        </IonCardContent>
                      )}
                    />
                  ))}
                </IonCol>
                {/* <IonCol>
                  <IonText>
                    <p>@TODO add filters</p>
                  </IonText>
                </IonCol> */}
              </IonRow>
            </IonGrid>
          </BlockInner>
        </Block>
      </IonContent>
    </Page>
  );
}
