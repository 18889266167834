import { BaseGetRequest } from '@vitrona/base-request';
import { of } from 'rxjs';
import env from '@vitrona/env';

import { IAPIOffer } from './types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEmptyContext {}

export class CreateOfferRequest extends BaseGetRequest<IEmptyContext> {
  public url = `${env.get<string>('API_URL')}/offers/`;
  method = 'POST';
  expectedStatus = 201;

  public static request() {
    return new CreateOfferRequest().makeRequest({});
  }
}

export class AbortOfferRequest extends BaseGetRequest<{ id: string }> {
  public url = `${env.get<string>('API_URL')}/offers/`;
  public method = 'DELETE';
  public expectedStatus = 204;

  public static request({ id }: { id: string }) {
    return new AbortOfferRequest().makeRequest({ id });
  }

  public getUrl({ id }: { id: string }) {
    return `${this.url}${id}/`;
  }

  public getSuccessResponse(response: Response) {
    return response.text();
  }
}

export class SaveOfferRequest extends BaseGetRequest<any> {
  public url = `${env.get<string>('API_URL')}/offers/`;
  public method = 'PUT';

  public static request(offer: Partial<IAPIOffer>) {
    return new SaveOfferRequest().makeRequest(offer);
  }

  public getBody(offer: Partial<IAPIOffer>) {
    return of(JSON.stringify(offer));
  }

  public getUrl({ id }: { id: string }) {
    return `${this.url}${id}/`;
  }
}

export class CreateOfferLineRequest extends BaseGetRequest<any> {
  public url = `${env.get<string>('API_URL')}/offer-lines/`;
  public method = 'POST';
  public expectedStatus = 201;

  public static request({ offerId, productId }) {
    return new CreateOfferLineRequest().makeRequest({
      offer: offerId,
      product: productId,
      finalDescription: '',
      finalPriceAmount: 0,
    });
  }

  public getBody(line) {
    return of(JSON.stringify(line));
  }
}

export class GetOfferLineRequest extends BaseGetRequest<{ id: string }> {
  public url = `${env.get<string>('API_URL')}/offer-lines/`;
  public method = 'GET';

  public static request(context: { id: string }) {
    return new GetOfferLineRequest().makeRequest(context);
  }

  public getUrl({ id }: { id: string }) {
    return `${this.url}${id}/`;
  }
}

export class CreateOptionRequest extends BaseGetRequest<any> {
  public url = `${env.get<string>('API_URL')}/sub-offer-lines/`;
  public method = 'POST';
  expectedStatus = 201;

  public static request(context) {
    return new CreateOptionRequest().makeRequest({
      finalDescription: '',
      finalPriceAmount: 0,
      ...context,
    });
  }

  public getBody(line) {
    return of(JSON.stringify(line));
  }
}

export class SaveOptionRequest extends BaseGetRequest<any> {
  public url = `${env.get<string>('API_URL')}/sub-offer-lines/`;
  public method = 'PATCH';

  public static request(context) {
    return new SaveOptionRequest().makeRequest({
      finalDescription: '',
      finalPriceAmount: 0,
      ...context,
    });
  }

  public getBody(option) {
    return of(JSON.stringify(option));
  }

  public getUrl({ id }: { id: string }) {
    return `${this.url}${id}/`;
  }
}

export class SaveOfferLineRequest extends BaseGetRequest<any> {
  public url = `${env.get<string>('API_URL')}/offer-lines/`;
  public method = 'PATCH';

  public static request(context) {
    return new SaveOfferLineRequest().makeRequest(context);
  }

  public getUrl({ id }: { id: string }) {
    return `${this.url}${id}/`;
  }

  public getBody(line) {
    return of(JSON.stringify(line));
  }
}

export class RemoveOfferLineRequest extends BaseGetRequest<any> {
  public url = `${env.get<string>('API_URL')}/offer-lines/`;
  public method = 'DELETE';
  public expectedStatus = 204;

  public static request(context) {
    return new RemoveOfferLineRequest().makeRequest(context);
  }

  public getUrl({ id }: { id: string }) {
    return `${this.url}${id}/`;
  }

  public getBody(line) {
    return of(JSON.stringify(line));
  }

  public getSuccessResponse(response: Response) {
    return response.text();
  }
}
