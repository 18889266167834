import React from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { closeCircleOutline as closeIcon } from 'ionicons/icons';

import { LegacyModal } from '@vitrona/ui';

// @TODO move
export function ReportSummary({
  isOpen = false,
  onDidDismiss,
  children = null,
  modalRef,
  presentingElement,
}: {
  isOpen?: boolean;
  onDidDismiss: () => void;
  children?: React.ReactNode;
}) {
  return (
    <LegacyModal
      modalRef={modalRef}
      presentingElement={presentingElement}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <IonPage>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Offerte - samenvatting</IonTitle>

            <IonButtons slot="secondary">
              <IonButton onClick={onDidDismiss}>
                <IonIcon slot="end" icon={closeIcon} />
                Sluiten
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {children}
      </IonPage>
    </LegacyModal>
  );
}
