import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { useParams } from 'react-router';
import { ExploreContainer } from '../../ExplorerContainer';
import './Page.css';

export function Page({
  title,
  children,
  renderHeaderToolbarSecondary = () => null,
}: {
  children?: React.ReactNode;
  title?: string;
  renderHeaderToolbarSecondary?: () => null | React.ReactNode;
}) {
  // const { name } = useParams<{ name: string }>();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle style={{ textTransform: 'capitalize' }}>{title}</IonTitle>

          {renderHeaderToolbarSecondary()}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}?</IonTitle>
          </IonToolbar>
        </IonHeader>

        {children}

        {/* <ExploreContainer name={name} /> */}
      </IonContent>
    </IonPage>
  );
}
