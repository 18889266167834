import React from 'react';
import { useDispatch } from 'react-redux';
import { History } from 'history';
import { onLocationChanged } from 'connected-react-router/immutable';

/**
 *
 * Regarding initialPop: https://github.com/supasate/connected-react-router/blob/a1053804b1097cda52e14b4e52b5a0ea3a421ede/src/ConnectedRouter.js#L74
 */
export function HistorySnitch({
  history,
  initialPop = true,
}: {
  history: History;
  initialPop?: boolean;
}) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    initialPop &&
      dispatch(onLocationChanged(history.location, history.action, true));

    const unsubscribe = history.listen((...args) => {
      dispatch(onLocationChanged(...args));
    });

    return unsubscribe;
  }, [dispatch, history, initialPop]);

  return null;
}
