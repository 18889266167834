import { actionCreatorFactory } from 'typescript-fsa';
import {
  IAPIOffer,
  IAPIOfferLine,
  IAPIOfferLineDetail,
  IAPISubOfferLine,
} from '@vitrona/api/offer';

type Offer = {
  id: string;
} & Partial<{
  email: string;
}>;

const actionCreator = actionCreatorFactory('offer');

export const createOffer = actionCreator('CREATE');
export const createOfferAsync = actionCreator.async<void, IAPIOffer>('CREATE');

export const editOfferLine = actionCreator<{ id: string; productId: string }>(
  'EDIT_LINE'
);
export const cancelOfferLineEdit = actionCreator('CANCEL_LINE_EDIT');
export const createOfferLine = actionCreator<{
  offerId: string;
  productId: string;
}>('CREATE_LINE');
export const createOfferLineAsync = actionCreator.async<
  { offerId: string; productId: string },
  {
    id: string;
    offerId: string;
    productId: string;
  }
>('CREATE_LINE');

export const saveOfferLine = actionCreator<{
  id: string;
  productId: string;
  offerId: string;
  value: unknown;
}>('SAVE_LINE');
export const saveOfferLineAsync = actionCreator.async<
  { id: string; productId: string; value: IAPIOfferLine },
  IAPIOfferLineDetail
>('SAVE_LINE');

export const removeOfferLine = actionCreator<{
  id: string;
  offerId: string;
}>('REMOVE_LINE');
export const removeOfferLineAsync = actionCreator.async<
  { id: string; offerId: string },
  { id: string; offerId: string }
>('REMOVE_LINE');

export const fetchOfferLine = actionCreator<{
  id: string;
}>('FETCH_LINE');
export const fetchOfferLineAsync = actionCreator.async<
  {
    id: string;
  },
  IAPIOfferLineDetail
>('FETCH_LINE');

export const saveOfferLineOptionsAsync = actionCreator.async<
  IAPISubOfferLine[],
  IAPISubOfferLine[]
>('SAVE_LINE_OPTIONS');

export const saveOffer = actionCreator<Offer>('SAVE');
export const saveOfferAsync = actionCreator.async<Offer, Offer>('SAVE');
export const completeOffer = actionCreator('COMPLETE');

export const abortOffer = actionCreator<{ id: string }>('ABORT');
export const abortOfferAsync = actionCreator.async<{ id: string }, void>(
  'ABORT'
);
