import {
  ignoreElements,
  tap,
  filter,
  mergeMap,
  catchError,
  map,
  withLatestFrom,
} from 'rxjs/operators';
import { of, concat, from, forkJoin } from 'rxjs';

import { DefaultEpic } from '@vitrona/state';

import {
  createOfferAsync,
  createOffer,
  abortOffer,
  abortOfferAsync,
  saveOffer,
  saveOfferAsync,
  createOfferLine,
  createOfferLineAsync,
  editOfferLine,
  saveOfferLine,
  saveOfferLineAsync,
  saveOfferLineOptionsAsync,
  fetchOfferLine,
  fetchOfferLineAsync,
  removeOfferLine,
  removeOfferLineAsync,
} from './actions';
import {
  AbortOfferRequest,
  CreateOfferLineRequest,
  CreateOfferRequest,
  CreateOptionRequest,
  GetOfferLineRequest,
  RemoveOfferLineRequest,
  SaveOfferLineRequest,
  SaveOfferRequest,
  SaveOptionRequest,
} from '@vitrona/api/offer';
import { ofAction } from './ofAction';
import { fetchProduct } from '@vitrona/state/catalogue';

export const createOfferEpic: DefaultEpic = (actions$) =>
  actions$.pipe(
    ofAction(createOffer),

    mergeMap(() =>
      concat(
        of(createOfferAsync.started()),
        CreateOfferRequest.request().pipe(
          map((result) => createOfferAsync.done({ result })),
          catchError((error) => of(createOfferAsync.failed({ error })))
        )
      )
    )
  );

export const abortOfferEpic: DefaultEpic = (actions$) =>
  actions$.pipe(
    ofAction(abortOffer),

    mergeMap(({ payload: { id } }) =>
      concat(
        of(abortOfferAsync.started({ id })),
        AbortOfferRequest.request({ id }).pipe(
          map(() => abortOfferAsync.done({ params: { id } })),
          catchError((error) =>
            of(abortOfferAsync.failed({ params: { id }, error }))
          )
        )
      )
    )
  );

export const saveOfferEpic: DefaultEpic = (actions$) =>
  actions$.pipe(
    ofAction(saveOffer),
    mergeMap(({ payload }) =>
      concat(
        of(saveOfferAsync.started(payload)),
        SaveOfferRequest.request(payload).pipe(
          map(() => saveOfferAsync.done({ params: payload, result: payload })),
          catchError((error) =>
            of(saveOfferAsync.failed({ params: payload, error }))
          )
        )
      )
    )
  );

export const createOfferLineEpic: DefaultEpic = (actions$) =>
  actions$.pipe(
    ofAction(createOfferLine),
    mergeMap(({ payload }) =>
      concat(
        of(createOfferLineAsync.started(payload)),
        CreateOfferLineRequest.request(payload).pipe(
          map((result) =>
            createOfferLineAsync.done({ params: payload, result })
          ),
          catchError((error) =>
            of(createOfferLineAsync.failed({ params: payload, error }))
          )
        )
      )
    )
  );

export const saveOfferLineEpic: DefaultEpic = (actions$, state$) =>
  actions$.pipe(
    ofAction(saveOfferLine),

    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { id, offerId, value },
        },
        state,
      ]) => {
        // @TODO Fix typings
        const { productVariant, options, quantity } = value;

        const dimensions = state
          .getIn(['catalogue', 'items', productVariant, 'properties'], {
            dimensions: [],
          })
          .dimensions.reduce(
            // prettier-ignore
            (acc, { variantValueProp, value }) => {
              acc[variantValueProp] = value;
              return acc;
            },
            {}
          );

        const optionIds: string[] = state.getIn([
          'offers',
          'items',
          id,
          'options',
        ]);
        const existingProductOptions = optionIds
          .map((id) => state.getIn(['offers', 'items', id]))
          .filter(Boolean);

        const subOfferLines = Object.entries(options)
          .map(([productOptionId, optionValue]) => {
            const option = existingProductOptions.find(
              (option) => option.properties.productOption === productOptionId
            );

            const optionId = option ? option.id : null;

            if (typeof optionValue === 'boolean') {
              return {
                id: optionId,
                productVariant,
                productOption: productOptionId,
                productOptionToggleValue: optionValue,
                offer: offerId,
                parent: id,
                ...dimensions,
              };
            }

            if (typeof optionValue === 'string') {
              return {
                id: optionId,
                productVariant,
                productOption: productOptionId,
                productOptionItemValue: optionValue,
                offer: offerId,
                parent: id,
                ...dimensions,
              };
            }

            return false;
          })
          .filter(Boolean);

        const subOfferLines$ = of(subOfferLines).pipe(
          mergeMap((options) =>
            concat(
              of(saveOfferLineOptionsAsync.started(options)),
              forkJoin(
                options.map((option) => {
                  if (option.id) {
                    return SaveOptionRequest.request(option);
                  }

                  return CreateOptionRequest.request(option);
                })
              ).pipe(
                map((result) =>
                  saveOfferLineOptionsAsync.done({
                    params: options,
                    result,
                  })
                ),
                catchError((error) =>
                  of(
                    saveOfferLineOptionsAsync.failed({
                      params: options,
                      error,
                    })
                  )
                )
              )
            )
          )
        );

        const data = { id, amountOfItems: quantity, productVariant };

        const offerLine$ = concat(
          // @TODO Fix typings for data/params
          of(saveOfferLineAsync.started(data)),
          SaveOfferLineRequest.request(data).pipe(
            map((result) => saveOfferLineAsync.done({ params: data, result })),
            catchError((error) =>
              of(saveOfferLineAsync.failed({ params: data, error }))
            )
          )
        );

        return concat(offerLine$, subOfferLines$);
      }
    )
  );

export const removeOfferLineEpic: DefaultEpic = (actions$) =>
  actions$.pipe(
    ofAction(removeOfferLine),
    mergeMap(({ payload }) =>
      concat(
        of(removeOfferLineAsync.started(payload)),
        RemoveOfferLineRequest.request(payload).pipe(
          map(() =>
            removeOfferLineAsync.done({ params: payload, result: payload })
          ),
          catchError((error) =>
            of(removeOfferLineAsync.failed({ params: payload, error }))
          )
        )
      )
    )
  );

export const fetchProductDetailsEpic: DefaultEpic = (actions$) =>
  actions$.pipe(
    ofAction(editOfferLine),
    mergeMap(({ payload: { id, productId } }) => {
      const data = { id };

      const offerLine$ = concat(
        of(fetchOfferLine(data)),
        of(fetchOfferLineAsync.started(data)),
        GetOfferLineRequest.request(data).pipe(
          map((result) => fetchOfferLineAsync.done({ params: data, result })),
          catchError((error) =>
            of(fetchOfferLineAsync.failed({ params: data, error }))
          )
        )
      );

      return concat(
        // prettier-ignore
        of(fetchProduct({ id: productId })),
        offerLine$
      );
    })
  );
