import template from 'lodash-es/template';

export type TemplateFn = (options: { [key: string]: string }) => string;
export type AllowedValues =
  | boolean
  | string
  | number
  | Array<string | number>
  | TemplateFn;

export type SettingsOperator = (settings: Settings) => Settings;

type FormatterFn = (values?: {
  [value: string]: AllowedValues;
}) => AllowedValues;
type Formatter = string | FormatterFn;

interface IResolvedSettings {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface IDefinition {
  settingName: string;
  dependencies: string[];
  formatter: FormatterFn;
}
const ROOT = '__root__';

export class Settings {
  private _definitionMap: { [key: string]: IDefinition } = {};
  private _resolvedSettings: IResolvedSettings = null;

  public pipe(
    ...operators: Array<(settingsIn: Settings) => Settings>
  ): Settings {
    return operators.reduce((acc: Settings, operator) => operator(acc), this);
  }

  public define(settingName: string, value: AllowedValues): this;
  public define(
    settingName: string,
    dependencies: string[],
    formatter: Formatter
  ): this;
  public define(
    settingName: string,
    value: AllowedValues | string[],
    formatter?: Formatter
  ) {
    if (formatter) {
      return this._defineWithDependencies(
        settingName,
        value as string[],
        formatter
      );
    }
    return this._defineFixed(settingName, value as AllowedValues);
  }

  public toJS() {
    this._resolvedSettings = this._resolve();
    return this;
  }

  public get<T>(key: string, fallbackValue?: T): T {
    if (this._resolvedSettings === null) {
      console.warn('You should call toJS() before accessing settings');
      return null;
    }

    const value = this._resolvedSettings[key];
    if (value === undefined && fallbackValue === undefined) {
      console.warn(`Accessing undefined setting ${key} without fallback value`);
    }
    if (value === undefined) {
      return fallbackValue;
    }
    return value as T;
  }

  private _buildTree() {
    return Object.entries(this._definitionMap).reduce(
      (
        dependencyList: {
          [node: string]: { depth: number; children: string[] };
        },
        [settingName, definition]
      ) => {
        if (dependencyList[settingName] === undefined) {
          dependencyList[settingName] = { depth: -1, children: [] };
        }

        definition.dependencies.forEach((dependency) => {
          if (
            dependency !== ROOT &&
            this._definitionMap[dependency] === undefined
          ) {
            console.warn(
              `[settings] Setting '${dependency}' is undefined, but is neccessary for determining '${settingName}'`
            );
          }
          if (dependencyList[dependency] === undefined) {
            dependencyList[dependency] = { depth: -1, children: [] };
          }
          dependencyList[dependency].children.push(settingName);
        });
        return dependencyList;
      },
      { [ROOT]: { depth: 0, children: [] } }
    );
  }

  private _resolve(): Record<string, unknown> {
    const tree = this._buildTree();

    // Do a Breadth First Tree traversal to set the depths correctly
    let queue = [ROOT];
    while (queue.length > 0) {
      const currentNodeId = queue.shift();
      const currentNode = tree[currentNodeId];

      currentNode.children.forEach((childId) => {
        tree[childId].depth = currentNode.depth + 1;
      });

      queue = queue.concat(currentNode.children);
    }

    // Sort definitions by depth lowest-highest so always all dependencies are resolved first.
    const resolveOrder = Object.values(this._definitionMap).sort(
      (a, b) => tree[a.settingName].depth - tree[b.settingName].depth
    );

    const resolvedSettings: IResolvedSettings = {};
    resolveOrder.forEach((definition) => {
      // Resolve all dependencies.
      const dependencies = definition.dependencies.reduce(
        (acc, dependency) => ({
          ...acc,
          [dependency]: resolvedSettings[dependency],
        }),
        {}
      );

      // Set value
      resolvedSettings[definition.settingName] = definition.formatter(
        dependencies
      );
    });

    return resolvedSettings;
  }

  protected _defineFixed(settingName: string, value: AllowedValues): this {
    this._definitionMap[settingName] = {
      settingName,
      dependencies: [ROOT],
      formatter: () => value,
    };
    return this;
  }

  protected _defineWithDependencies(
    settingName: string,
    dependencies: string[],
    formatter: Formatter
  ): this {
    this._definitionMap[settingName] = {
      settingName,
      dependencies,
      formatter:
        typeof formatter === 'string' ? template(formatter) : formatter,
    };
    return this;
  }
}
