import React from 'react';
import { IonModal } from '@ionic/react';

export function Modal({
  isOpen,
  onDidDismiss = () => void 0,
  children,
  modalRef,
  ...rest
}: {
  isOpen: boolean;
  onDidDismiss: () => void;
  children: React.ReactNode;
  modalRef?: React.Ref<HTMLIonModalElement>;
  backdropDismiss?: boolean;
}) {
  return (
    <IonModal
      ref={modalRef}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      {...rest}
    >
      {children}
    </IonModal>
  );
}
