import { createSelector, createStructuredSelector } from 'reselect';
import { OrderedSet } from 'immutable';
import { CatalogueState, ProductKind } from './reducer';
import { Product } from '@vitrona/api/catalogue';

export function getCatalogueState(state) {
  return state.get('catalogue') as CatalogueState;
}
export function getCatalogueItems(state) {
  return state.getIn(['catalogue', 'items']) as CatalogueState['items'];
}
export function getCatalogueTree(state) {
  return state.getIn(['catalogue', 'tree']) as CatalogueState['tree'];
}

export const selectProductKindIds = createSelector(
  getCatalogueTree,
  (tree) =>
    tree.get('byProductKind', OrderedSet<string>()) as OrderedSet<string>
);

export const selectProductKinds = createSelector(
  getCatalogueItems,
  selectProductKindIds,
  (items, idList) =>
    idList
      // prettier-ignore
      .filter((id) => items.has(id))
      .map((id) => items.get(id)) as OrderedSet<ProductKind>
);

export function productKindSelectorFactory(id: string) {
  const selectProductKind = createSelector(
    getCatalogueItems,
    (items) => items.get(id, Product.empty()) as ProductKind
  );

  return selectProductKind;
}

export function productsByKindSelectorFactory(id: string) {
  const selectProductIds = createSelector(
    getCatalogueTree,
    (tree) => tree.get(id, OrderedSet<string>()) as OrderedSet<string>
  );

  const selectProducts = createSelector(
    getCatalogueItems,
    selectProductIds,
    (items, idList) =>
      idList
        // prettier-ignore
        .filter((id) => items.has(id))
        .map((id) => items.get(id)) as OrderedSet<Product>
  );

  return selectProducts;
}
