export const product = {
  id: '88d48fa0-dd4d-40c4-bfd7-5fff019a2b75',
  title: 'KNIKARMSCHERM TYPE FOKKER 1600 SEMI CASSETTE',
  productKind: 'Knikarmschermen',
  brand: 'Vitrona Huismerk / Smits',
  model: 'Fokker 1600',
  type: '',
  description: '',
  image: null,
  dimension1Label: 'Breedte',
  dimension2Label: 'Uitval',
  dimension3Label: '',
  productVariants: [
    {
      id: 'ca7253ef-0168-491b-8cd9-f549f93477e1',
      priceAmount: 1616.0,
      dimension1: '2500',
      dimension2: '1500',
      dimension3: null,
    },
    {
      id: 'ee25e35f-56b8-438b-9a1b-4d63dc7e3aa6',
      priceAmount: 1620.0,
      dimension1: '2750',
      dimension2: '1500',
      dimension3: null,
    },
    {
      id: 'b0def582-5a5e-4a88-ace5-97f03b6bedc6',
      priceAmount: 1626.0,
      dimension1: '3000',
      dimension2: '1500',
      dimension3: null,
    },
    {
      id: 'fcb98aed-084a-4bc5-aeb9-e1b02f895cbe',
      priceAmount: 1642.0,
      dimension1: '3250',
      dimension2: '1500',
      dimension3: null,
    },
    {
      id: 'a59f73d7-0bb6-41a6-ba63-791aa2c3ec63',
      priceAmount: 1658.0,
      dimension1: '2500',
      dimension2: '2000',
      dimension3: null,
    },
    {
      id: '3edbe221-a45c-4b69-b6a8-4f521892a505',
      priceAmount: 1658.0,
      dimension1: '3500',
      dimension2: '1500',
      dimension3: null,
    },
    {
      id: 'a012da4c-ebbf-4239-b9f6-87bbd93c70f6',
      priceAmount: 1663.0,
      dimension1: '2750',
      dimension2: '2000',
      dimension3: null,
    },
    {
      id: '6e46fe89-7f0c-40fa-bbed-0235cc93eb78',
      priceAmount: 1671.0,
      dimension1: '3000',
      dimension2: '2000',
      dimension3: null,
    },
    {
      id: '9b9f7cd7-f9fa-40be-8480-39a9f530614e',
      priceAmount: 1688.0,
      dimension1: '3750',
      dimension2: '1500',
      dimension3: null,
    },
    {
      id: '76aed961-9fe4-47ea-8a46-2426396d344e',
      priceAmount: 1703.0,
      dimension1: '3250',
      dimension2: '2000',
      dimension3: null,
    },
    {
      id: 'd96649b4-6c97-4658-8fb7-15484a8b4574',
      priceAmount: 1715.0,
      dimension1: '4000',
      dimension2: '1500',
      dimension3: null,
    },
    {
      id: 'd515ddfa-da07-4d18-bb0f-513864de12a1',
      priceAmount: 1720.0,
      dimension1: '3500',
      dimension2: '2000',
      dimension3: null,
    },
    {
      id: '6acf9ed9-dc4c-42b0-9240-f7d4640f78fe',
      priceAmount: 1739.0,
      dimension1: '4250',
      dimension2: '1500',
      dimension3: null,
    },
    {
      id: '180b6676-0221-4c58-ac83-7b05689c3eb7',
      priceAmount: 1747.0,
      dimension1: '3000',
      dimension2: '2500',
      dimension3: null,
    },
    {
      id: 'f4bb537a-87e8-4e75-875e-104c00d85d2c',
      priceAmount: 1747.0,
      dimension1: '3750',
      dimension2: '2000',
      dimension3: null,
    },
    {
      id: 'a5f9004b-323a-458e-bd9c-20e98a56ce08',
      priceAmount: 1756.0,
      dimension1: '3250',
      dimension2: '2500',
      dimension3: null,
    },
    {
      id: 'f5db3e6c-3fda-4da3-afc1-e0acb67740d0',
      priceAmount: 1760.0,
      dimension1: '4500',
      dimension2: '1500',
      dimension3: null,
    },
    {
      id: '1b0b1b50-118d-4ca5-96bf-f3571971f45c',
      priceAmount: 1766.0,
      dimension1: '3500',
      dimension2: '2500',
      dimension3: null,
    },
    {
      id: '6ab7e672-8f1d-4325-89e4-8d6ba4fd2190',
      priceAmount: 1781.0,
      dimension1: '4000',
      dimension2: '2000',
      dimension3: null,
    },
    {
      id: '7075dd47-6d27-4d4a-a948-7874d1d48473',
      priceAmount: 1788.0,
      dimension1: '3250',
      dimension2: '3000',
      dimension3: null,
    },
    {
      id: '8c211590-606b-4d0f-b554-52316cfd3276',
      priceAmount: 1798.0,
      dimension1: '3500',
      dimension2: '3000',
      dimension3: null,
    },
    {
      id: '8c7b2cf8-0e82-4944-9f60-1f1a78341409',
      priceAmount: 1798.0,
      dimension1: '4750',
      dimension2: '1500',
      dimension3: null,
    },
    {
      id: '001b0134-0e2b-49b9-95fe-31af80a8fbb7',
      priceAmount: 1803.0,
      dimension1: '3750',
      dimension2: '2500',
      dimension3: null,
    },
    {
      id: '15d70715-fc42-44fb-a439-3758841de326',
      priceAmount: 1803.0,
      dimension1: '4250',
      dimension2: '2000',
      dimension3: null,
    },
    {
      id: '5eac331e-e0d1-4517-8174-3db7f4b13afc',
      priceAmount: 1827.0,
      dimension1: '4500',
      dimension2: '2000',
      dimension3: null,
    },
    {
      id: '1f56b72f-add6-4847-a0bc-46111eb43136',
      priceAmount: 1831.0,
      dimension1: '3750',
      dimension2: '3000',
      dimension3: null,
    },
    {
      id: '1c919202-a26d-443b-ad46-f8d5a6f68b38',
      priceAmount: 1831.0,
      dimension1: '5000',
      dimension2: '1500',
      dimension3: null,
    },
    {
      id: '0acce248-3587-4b7d-9a42-167c4592b3a7',
      priceAmount: 1844.0,
      dimension1: '4000',
      dimension2: '2500',
      dimension3: null,
    },
    {
      id: '833cc6fc-1500-4a32-a0c3-b0dab90edba9',
      priceAmount: 1857.0,
      dimension1: '5250',
      dimension2: '1500',
      dimension3: null,
    },
    {
      id: '064800ee-ebe9-41bc-bb6e-0d020202b224',
      priceAmount: 1862.0,
      dimension1: '5500',
      dimension2: '1500',
      dimension3: null,
    },
    {
      id: 'c9131a02-08a8-42a4-9838-0e8652aece17',
      priceAmount: 1866.0,
      dimension1: '4750',
      dimension2: '2000',
      dimension3: null,
    },
    {
      id: '51b71529-5e44-4111-b4b7-7d0cca061dab',
      priceAmount: 1867.0,
      dimension1: '4250',
      dimension2: '2500',
      dimension3: null,
    },
    {
      id: 'f26f663a-5e57-4e20-bd0e-ea3728781646',
      priceAmount: 1880.0,
      dimension1: '4000',
      dimension2: '3000',
      dimension3: null,
    },
    {
      id: '439908fd-c49b-40e6-81cf-1000f6bd2a7e',
      priceAmount: 1890.0,
      dimension1: '4500',
      dimension2: '2500',
      dimension3: null,
    },
    {
      id: '0930f735-508d-4806-9f6e-781d67840856',
      priceAmount: 1901.0,
      dimension1: '4250',
      dimension2: '3000',
      dimension3: null,
    },
    {
      id: 'f7ea1ff8-5376-4095-9b19-1278f9eafdb9',
      priceAmount: 1906.0,
      dimension1: '5000',
      dimension2: '2000',
      dimension3: null,
    },
    {
      id: '8ba1e127-4a14-4718-b05b-bfd7a580db6c',
      priceAmount: 1917.0,
      dimension1: '5250',
      dimension2: '2000',
      dimension3: null,
    },
    {
      id: 'c2510209-7192-48d0-828f-a6e85ad6a3a2',
      priceAmount: 1925.0,
      dimension1: '4500',
      dimension2: '3000',
      dimension3: null,
    },
    {
      id: '0a56ff13-134a-486a-8ce8-cc33417be37a',
      priceAmount: 1925.0,
      dimension1: '5500',
      dimension2: '2000',
      dimension3: null,
    },
    {
      id: '892bc773-14da-4a76-9a7c-db44bd3af2df',
      priceAmount: 1935.0,
      dimension1: '4750',
      dimension2: '2500',
      dimension3: null,
    },
    {
      id: '1ffb7158-89d5-4cc3-b497-5f75b823a64c',
      priceAmount: 1975.0,
      dimension1: '4750',
      dimension2: '3000',
      dimension3: null,
    },
    {
      id: '85febb09-42dd-4f9d-810b-b37f063f135c',
      priceAmount: 1979.0,
      dimension1: '5000',
      dimension2: '2500',
      dimension3: null,
    },
    {
      id: '1794de4d-263b-43f4-ad73-afd7b41bab93',
      priceAmount: 2000.0,
      dimension1: '5250',
      dimension2: '2500',
      dimension3: null,
    },
    {
      id: '868328f2-01ac-4276-afa0-c8202efe579e',
      priceAmount: 2020.0,
      dimension1: '5500',
      dimension2: '2500',
      dimension3: null,
    },
    {
      id: 'f7b59aea-b796-47db-b106-a94036eec0d2',
      priceAmount: 2022.0,
      dimension1: '5000',
      dimension2: '3000',
      dimension3: null,
    },
  ],
  options: [
    {
      id: '2f1150ca-2c5e-4540-9019-f5b85b600003',
      type: 'productoptionchoice',
      items: [
        {
          id: '0c46c0b5-1516-490e-8c3a-77f54475e383',
          product: null,
          overwrittenPrice: 0.0,
          title: 'Technisch Zilver, RAL 9001 of RAL 7016 STR',
        },
        {
          id: '5e1aa4d0-9476-4c3b-969d-b7cee49c25f0',
          product: null,
          overwrittenPrice: 0.0,
          title: 'Technisch Zilver',
        },
        {
          id: 'e5746922-20cd-47bd-8715-9e075062573e',
          product: null,
          overwrittenPrice: 0.0,
          title: 'RAL 9001',
        },
        {
          id: '6e8c5c72-898c-402c-b3f1-2733446596b6',
          product: null,
          overwrittenPrice: 0.0,
          title: 'RAL 7016 STR',
        },
      ],
      overwrittenPrice: null,
      title: 'Kleur profielen',
      isRequired: true,
      sharedKey: 'FOK16_PROF',
      initialValue: null,
      resourcetype: 'ProductOptionChoice',
    },
    {
      id: '9fbd44d9-9270-4982-82b6-9e037294252f',
      type: 'productoptionchoice',
      items: [
        {
          id: '1c9aeb1e-f454-4f5e-8658-3e80e9bbcb7d',
          product: null,
          overwrittenPrice: null,
          title: 'Tibelly collectie - doeknummer nader te bepalen',
        },
        {
          id: 'b5874214-44b0-4b2a-8812-46eeeff83d26',
          product: null,
          overwrittenPrice: null,
          title: 'T356 - Tibelly -  Grijs/creme blok 15 cm',
        },
        {
          id: '6a0f6e4e-5bb5-41b1-98af-afe8ed4aabe8',
          product: null,
          overwrittenPrice: null,
          title: 'T515 - Tibelly - Grijs/Creme blok 10 cm',
        },
        {
          id: '822991fd-79bc-4e60-94f9-738153967148',
          product: null,
          overwrittenPrice: null,
          title: 'T138 - Tibelly - Uni lichtgrijs chine',
        },
        {
          id: '133d528e-da43-48f7-a77e-d61c1b0d57e2',
          product: null,
          overwrittenPrice: null,
          title: 'T384 - Tibelly - Antra/Grijs blok 15 cm',
        },
        {
          id: '989f0843-93a1-4ebe-86f5-c68376dec6be',
          product: null,
          overwrittenPrice: null,
          title: 'T273 - Tibelly - Grijs Fantasie Veluwe',
        },
        {
          id: '407ce5a6-4264-420b-9b2a-d205efdfbe49',
          product: null,
          overwrittenPrice: null,
          title: 'T507 - Tibelly - Zwart/Creme blok 10 cm',
        },
        {
          id: '62905d6d-36a4-4649-ad5f-8de96e377858',
          product: null,
          overwrittenPrice: null,
          title: 'T254 - Tibelly - Antra/Creme Fantasie Meierij',
        },
        {
          id: 'c690523f-3b35-4c28-bead-af9075ea35ee',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 0001 120 Ecru Uni - Effen Essentials - Beige & Brown 20',
        },
        {
          id: '4fa6fb2e-1ad8-457f-aa83-2b51dd6bb564',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 0003 120 Vert Uni - Effen Essentials - Green 29',
        },
        {
          id: 'adb46735-ef1f-4f1e-ab18-18e4115de1a7',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 0017 120 Bleu Uni - Effen Essentials - Blue 27',
        },
        {
          id: '81fde281-6cea-4b53-9100-6d59975c98e2',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 0018 120 Orange Uni - Effen Essentials - Yellow & Orange 23',
        },
        {
          id: '72af1fc4-feae-49da-a684-77705dcba6f3',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 0020 120 Vermillon Uni - Effen Essentials - Red & Pink 25',
        },
        {
          id: 'b459a1bc-ac28-46e1-bf94-d7686392295a',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 0034 120 Sable Uni - Effen Essentials - Yellow & Orange 23',
        },
        {
          id: '5a806324-af60-4f8e-8701-0dea7bbea89b',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 0613 120 Marron Uni - Effen Essentials - Beige & Brown 21',
        },
        {
          id: 'bd7739f0-a355-47ec-8308-d0102566dae7',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 0681 120 Dune Uni - Effen Essentials - Beige & Brown 21',
        },
        {
          id: 'c46b4202-e832-4eb9-944a-a7e4e9713ecb',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 0806 120 Champagne Uni - Effen Essentials - Yellow & Orange 23',
        },
        {
          id: '7e351fbd-3992-4b62-a4b9-124b831af90c',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 0853 120 Olive Uni - Effen Essentials - Green 29',
        },
        {
          id: '311759ec-0dd5-4e95-ab2c-38de3f52c4f5',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 0867 120 Mandarine Uni - Effen Essentials - Yellow & Orange 23',
        },
        {
          id: '2a616a23-a090-484c-8201-29585393b33b',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 3914 120 Rouge Uni - Effen Essentials - Red & Pink 25',
        },
        {
          id: '59467518-1569-4174-bcad-0d9f5648d969',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 6020 120 Grège Uni - Effen Essentials - Beige & Brown 20',
        },
        {
          id: '8d59aa48-c108-4cb0-9ad8-8e84e5c0023e',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 6022 120 Marine Uni - Effen Essentials - Blue 27',
        },
        {
          id: '357b0c46-9f42-47d9-9494-3e44fa59749f',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 6023 120 Réséda Uni - Effen Essentials - Green 29',
        },
        {
          id: '187dcc08-7e6f-4772-aa28-aa0ab7fae5a7',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 6028 120 Noir Uni - Effen Essentials - Grey 19',
        },
        {
          id: '7dcb3a03-4fc3-4c03-883c-1efd295bb104',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 6088 120 Gris Uni - Effen Essentials - Grey 19',
        },
        {
          id: '3edf3cc8-205f-433a-92c8-e532e86db25c',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 6196 120 Pierre Uni - Effen Essentials - Grey 18',
        },
        {
          id: '1b9deadd-452a-4be1-8814-c9ea54e8ec1a',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 6316 120 Jaune Uni - Effen Essentials - Yellow & Orange 23',
        },
        {
          id: 'cf1264c9-fdb1-46e6-a927-56e95e016643',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 6318 120 Blé Uni - Effen Essentials - Yellow & Orange 23',
        },
        {
          id: 'a7001951-2a0d-406a-9254-c8c84f4b129d',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 6319 120 Nuages Flammé Uni - Flammé Essentials - Beige & Brown 20',
        },
        {
          id: 'c1f88cfd-ea98-404f-a9d1-64ba70c1f19c',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 6435 120 Cerise Uni - Effen Essentials - Red & Pink 25',
        },
        {
          id: '3d046b27-566d-4a4f-9310-e1ec82a53b49',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 6610 120 Vanille Uni - Effen Essentials - Yellow & Orange 23',
        },
        {
          id: '30fe4f9c-6ff1-4d28-bcd4-01e8da64ba15',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 6687 120 Forêt Uni - Effen Essentials - Green 29',
        },
        {
          id: '7fe13108-f4b4-4e01-890a-811dfca0ec6d',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 6688 120 Turquoise Uni - Effen Essentials - Blue 27',
        },
        {
          id: '7c93947e-109a-4155-aa38-3b3d01b79f35',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 6720 120 Saphir Uni - Effen Essentials - Blue 26',
        },
        {
          id: '687dca77-7ec9-459b-be88-d6b3a367b614',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 7109 120 Sienne Blue Fantasiestreep Essentials - Blue 27',
        },
        {
          id: '678e70ff-6e6e-4346-81d3-ad51934b5f4f',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 7131 120 Multico Flammé Uni - Flammé Essentials - Yellow & Orange 23',
        },
        {
          id: '2c2d0acf-b529-4927-b3a7-1b466a534ba4',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 7132 120 Graphite Flammé Uni - Flammé Essentials - Grey 18',
        },
        {
          id: '980be1ec-b38e-406b-9a23-4d336ff31c21',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 7133 120 Naturel Uni - Effen Essentials - Beige & Brown 21',
        },
        {
          id: '1cb1805e-55e4-4c6b-b3b7-7984699078eb',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 7244 120 Amande Uni - Effen Essentials - Green 29',
        },
        {
          id: '927c3068-e3b3-4661-bd5c-94504ecbeaf3',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 7264 120 Océan Uni - Effen Essentials - Blue 27',
        },
        {
          id: '4d8dd89b-e595-4c34-bc69-9fef2c02fd60',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 7297 120 Emeraude Uni - Effen Essentials - Green 29',
        },
        {
          id: '6e3eab39-8fb4-4ae6-a8bf-3faaf14eaa69',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 7330 120 Charcoal Tweed Uni - Tweed Essentials - Grey 19',
        },
        {
          id: '47cd4961-27c7-4f63-bb7e-a6d3419907ea',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 7466 120 Chicago Blue Fantasiestreep Essentials - Blue 27',
        },
        {
          id: '7bcce5da-a01d-4889-86e4-c7a44c0ee24b',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 7467 120 Chicago Yellow Fantasiestreep Essentials - Yellow & Orange 23',
        },
        {
          id: '16e89cf1-80a5-4c00-8b88-20ed7e95e879',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 7548 120 Ivoire Uni - Effen Essentials - Yellow & Orange 23',
        },
        {
          id: '6d47853b-b02f-4fe8-b446-3c64d2f637c0',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 7552 120 Argent Uni - Effen Essentials - Grey 18',
        },
        {
          id: '13d76efc-d1e5-427a-99f2-6dd8cc5f0335',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 7554 120 Cassis Uni - Effen Essentials - Red & Pink 25',
        },
        {
          id: '3ec9544c-90bd-43ba-8ab7-e31c08c85dbf',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 7557 120 Menthe Uni - Effen Essentials - Green 29',
        },
        {
          id: '78d317f7-399b-4bfb-9661-6823b2eeebad',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 7559 120 Taupe Uni - Effen Essentials - Beige & Brown 21',
        },
        {
          id: '06338006-d3f1-4f0c-aa71-e8049d596eae',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 7560 120 Paille Uni - Effen Essentials - Yellow & Orange 23',
        },
        {
          id: '4cb48fa2-3ef6-4948-8c37-cd09dc98e85b',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 7703 120 Citron Uni - Effen Essentials - Yellow & Orange 23',
        },
        {
          id: '776f4dac-7e95-456d-b7ab-ddd983c01939',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 7972 120 Perle Flammé Uni - Flammé Essentials - Grey 18',
        },
        {
          id: 'f9bf0db3-e137-4857-9222-f69805d89ee0',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8200 120 Chanvre Uni - Effen Essentials - Beige & Brown 21',
        },
        {
          id: '4052922c-3d95-4754-bc65-f6acd44e768c',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8201 120 Fougère Uni - Effen Essentials - Green 29',
        },
        {
          id: '6d1d7b8d-f129-4b22-afee-e03c595c99a7',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8202 120 Méditerranée Uni - Effen Essentials - Blue 26',
        },
        {
          id: '1d6692bd-71ea-4b42-88ca-1b4b812c9355',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8203 120 Ardoise Uni - Effen Essentials - Grey 19',
        },
        {
          id: '8998614e-7441-4f96-a2db-084c966c9b73',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8204 120 Bleuet Uni - Effen Essentials - Blue 27',
        },
        {
          id: 'db05bfb8-ca7a-4063-96d0-460fc098958e',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8205 120 Papaye Uni - Effen Essentials - Red & Pink 25',
        },
        {
          id: '7fea808b-6997-4459-be22-0e1cc515ef20',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8206 120 Bordeaux Uni - Effen Essentials - Red & Pink 25',
        },
        {
          id: '44055383-9590-4dce-8a6f-5685b1437c71',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8207 120 Châtaigne Uni - Effen Essentials - Red & Pink 25',
        },
        {
          id: 'b9d1139f-f0dd-424f-bb3e-3ff742f7fd21',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8210 120 Sienne Wheat Fantasiestreep Essentials - Yellow & Orange 23',
        },
        {
          id: '1f1b4729-5e65-4b66-a4e0-f9492e39041a',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8211 120 Sienne Red Fantasiestreep Essentials - Red & Pink 25',
        },
        {
          id: '0862cf04-0f73-4f02-859c-dceaa385ef04',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8238 120 Bleu Nuit Uni - Effen Essentials - Blue 27',
        },
        {
          id: '48886665-76e1-4e7d-8d62-e7cb2f013eb4',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8396 120 Souris Chiné Uni - Chiné Essentials - Grey 18',
        },
        {
          id: '22f35fb6-81c8-4ce4-a443-cf93fe857c51',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8402 120 BS Forêt-Ecru Blokstreep Essentials - Green 29',
        },
        {
          id: '44800d03-c80d-43e3-9880-89bd01d02c81',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8553 120 BS Jaune Blokstreep Essentials - Yellow & Orange 23',
        },
        {
          id: '292db9d1-7121-48b2-9ff5-32d5b1ef2d98',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8555 120 BS Forêt-Naturel Blokstreep Essentials - Green 29',
        },
        {
          id: '4fe7a053-8e2f-49ed-8d72-0c09319ebfc6',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8556 120 BS Marine Blokstreep Essentials - Blue 27',
        },
        {
          id: '96ea3c6a-9dfa-4ae4-a94a-3fbc9b2d6bd8',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8557 120 BS Rouge Blokstreep Essentials - Red & Pink 25',
        },
        {
          id: '0f12e997-1625-4d6d-a073-d1e0162dabd8',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8600 120 Absinthe Uni - Effen Color Design Selection 14',
        },
        {
          id: '11d53348-6888-4c8c-99ff-ce475e02bd17',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8601 120 Mauve Uni - Effen Essentials - Red & Pink 25',
        },
        {
          id: '34ffbb4f-92e7-4f85-ae15-02186aad4c5a',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8612 120 Hardelot Yellow Fantasiestreep Essentials - Yellow & Orange 23',
        },
        {
          id: 'cd80aafd-c392-4be9-95e4-e48a85e04d9e',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8614 120 Hardelot Green Fantasiestreep Essentials - Green 29',
        },
        {
          id: 'aa76ae4e-090d-415c-96a9-aafa5ff9143a',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8630 120 Boston Green Fantasiestreep Essentials - Green 29',
        },
        {
          id: '3eb09e10-4be2-4749-8d1b-999043d018fa',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8776 120 Cacao Uni - Effen Essentials - Beige & Brown 21',
        },
        {
          id: 'da35c7fc-688d-425a-a316-6b98c4174843',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8777 120 Safran Uni - Effen Essentials - Yellow & Orange 23',
        },
        {
          id: '29ec460c-eb43-4cf7-b2b5-e78ca38c2912',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8778 120 Chardon Uni - Effen Essentials - Blue 26',
        },
        {
          id: '2613c806-bee8-481e-8719-c1348cca8d99',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8779 120 Bruyère Chiné Uni - Chiné Essentials - Beige & Brown 21',
        },
        {
          id: '9881f541-31c8-43cd-a952-00fcb1fb176d',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8854 120 Marseille Flammé Uni - Flammé Essentials - Yellow & Orange 23',
        },
        {
          id: '759b0138-ac53-4b92-811d-6a708cf8df3f',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8891 120 Toast Uni - Effen Essentials - Beige & Brown 21',
        },
        {
          id: 'e31235b6-6795-4fa9-80e2-500f509ebb84',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8901 120 Paon Uni - Effen Color Design Selection 14',
        },
        {
          id: '22631708-e65d-4436-aa80-2992a2ed8068',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8902 120 Beige Uni - Effen Essentials - Beige & Brown 21',
        },
        {
          id: '7be6f496-6532-408a-90b3-6cfc0e23ed2b',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8904 120 Lin Chiné Uni - Chiné Essentials - Beige & Brown 20',
        },
        {
          id: 'bf28e217-f44b-4cbf-b18b-732efb3d3f26',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8907 120 BS Gris Blokstreep Essentials - Grey 18',
        },
        {
          id: 'a071f6d4-d15c-462c-9926-1a80ac23f0e6',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8910 120 BS Bleu Blokstreep Essentials - Blue 27',
        },
        {
          id: 'f5d32cb2-110f-45c7-af22-9ba828e26571',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8919 120 BS Noir Blokstreep Essentials - Grey 19',
        },
        {
          id: '6e3bc947-2b92-4a9c-bab9-6518d4551422',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8921 120 BS Bruyère Blokstreep Essentials - Beige & Brown 21',
        },
        {
          id: '20517e72-c7e7-4476-a574-6b6b045691e1',
          product: null,
          overwrittenPrice: null,
          title: 'ORC 8922 120 BS Ardoise Blokstreep Essentials - Grey 19',
        },
        {
          id: 'b7e9325f-4151-4c44-b2eb-0efc274f08f2',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8931 120 Sienne Dark Grey Fantasiestreep Essentials - Grey 19',
        },
        {
          id: '1a515c76-c9c1-4c56-92c9-b564b686e72e',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC 8935 120 Hardelot Beige Fantasiestreep Essentials - Beige & Brown 21',
        },
        {
          id: 'd476a8a2-dea8-4ace-bd3c-d84d595b6d16',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC C030 120 BS Argile Blokstreep Essentials - Beige & Brown 20',
        },
        {
          id: 'fefe5291-c26b-433e-a0fb-86e50290857d',
          product: null,
          overwrittenPrice: null,
          title: 'ORC C031 120 BS Azur Blokstreep Essentials - Blue 27',
        },
        {
          id: '97d8ac6a-4ebf-4b80-b5d9-12ced5d1de97',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC C032 120 BS Granny Chiné Blokstreep Color Design Selection 14',
        },
        {
          id: 'd7c1ce7e-01e3-4425-9195-62921a999c7a',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC C033 120 BS Canard Chiné Blokstreep Essentials - Green 29',
        },
        {
          id: 'f0abf96c-6dc6-4549-8aed-0fd0ec68d39d',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D100 120 Sienne Beige Fantasiestreep Essentials - Beige & Brown 21',
        },
        {
          id: '50f2f113-cd29-4b27-8ee2-bddf885cdde1',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D103 120 Manosque Beige Fantasiestreep Essentials - Beige & Brown 20',
        },
        {
          id: '2fd1856c-e1ae-478c-b940-92d8d6b98f47',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D104 120 Manosque Green Fantasiestreep Essentials - Green 29',
        },
        {
          id: '5c8e5ddd-3ce6-4ac5-8156-9dcba0f18756',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D105 120 Manosque Purple Fantasiestreep Essentials - Red & Pink 25',
        },
        {
          id: 'c762a478-19bb-4912-b137-c9c27656bbf5',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D107 120 Manosque Yellow Fantasiestreep Essentials - Yellow & Orange 23',
        },
        {
          id: '7cd4d275-4bb9-4c50-9efe-34e0a5bd3497',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D108 120 Manosque Dark Grey Fantasiestreep Essentials - Grey 19',
        },
        {
          id: 'd2c5aa9f-d681-4b00-8052-6acef8e3880f',
          product: null,
          overwrittenPrice: null,
          title: 'ORC D113 120 Naples Grey Fantasiestreep Essentials - Grey 19',
        },
        {
          id: 'ebccf8a8-66ea-4b55-8045-f525ef485de4',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D298 120 Wide Chiné Grey Fantasiestreep Graphic Design Selection 6',
        },
        {
          id: 'fea89dc5-17eb-43fb-9d42-cbb6ebcd98ad',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D299 120 Wide Chiné Green Fantasiestreep Graphic Design Selection 9',
        },
        {
          id: 'c3089f97-ff47-4638-9291-603737d0e779',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D300 120 Wide Chiné Yellow Fantasiestreep Graphic Design Selection 9',
        },
        {
          id: 'b300f9b7-7909-412b-99fb-c7462220479e',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D301 120 Wide Chiné Beige Fantasiestreep Graphic Design Selection 6',
        },
        {
          id: 'c332a777-990e-4e87-a580-729d78d5ee51',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D302 120 Sienne Green Fantasiestreep Essentials - Green 29',
        },
        {
          id: '5905b19d-4a05-4e61-8914-684ef087b2dc',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D303 120 Sienne Yellow Fantasiestreep Essentials - Yellow & Orange 23',
        },
        {
          id: '5ff841b5-d5c0-47bf-a0c0-474dfbd0df98',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D304 120 Naples Light Grey Fantasiestreep Essentials - Grey 19',
        },
        {
          id: 'df2c81c9-c888-4e03-b16b-274bf24c1420',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D305 120 Naples Heather Fantasiestreep Essentials - Beige & Brown 20',
        },
        {
          id: '53e06c28-c32f-4c98-84be-1ac13287298f',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D306 120 Naples Hemp Fantasiestreep Essentials - Beige & Brown 20',
        },
        {
          id: '97aaad8c-b3b1-4281-93b7-d08280394d42',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D307 120 Naples Linen Fantasiestreep Essentials - Beige & Brown 20',
        },
        {
          id: 'e445bbb3-fcd0-4876-b0c1-a0add4b79cc2',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D308 120 Naples Dark Grey Fantasiestreep Essentials - Grey 19',
        },
        {
          id: '61471ee9-a0e1-4fc6-ba12-f8ef18f6847c',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D309 120 Manosque Grey Fantasiestreep Essentials - Grey 18',
        },
        {
          id: 'edb6413a-9ce4-4dfe-a075-f87bcf5fdb22',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D310 120 Chicago Green Fantasiestreep Essentials - Green 29',
        },
        {
          id: 'cfb4b3e1-d6f4-494a-81c6-146164d6797a',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D311 120 Chicago Beige Fantasiestreep Essentials - Beige & Brown 21',
        },
        {
          id: 'a9a7af3b-8852-4c49-8c1f-d7fdda683774',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D312 120 Hardelot Grey Fantasiestreep Essentials - Grey 19',
        },
        {
          id: 'e9a034f5-5e0a-4ab4-9f43-2c5787c0378b',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D313 120 Rome Red Fantasiestreep Essentials - Red & Pink 25',
        },
        {
          id: '9180aa09-eebc-4b7b-b4f2-d9188f96d445',
          product: null,
          overwrittenPrice: null,
          title: 'ORC D314 120 Rome Green Fantasiestreep Essentials - Green 29',
        },
        {
          id: 'a42c28c7-36d3-43db-bca9-9fa0f5a170b6',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D315 120 Rome Brown Fantasiestreep Essentials - Beige & Brown 21',
        },
        {
          id: 'a7f4859f-db37-4e6c-8fae-f804cfaf25e9',
          product: null,
          overwrittenPrice: null,
          title: 'ORC D316 120 Boston Grey Fantasiestreep Essentials - Blue 27',
        },
        {
          id: 'eaf3da5e-5421-4cf6-aa09-25c967c2251c',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D317 120 Boston Red Fantasiestreep Essentials - Red & Pink 25',
        },
        {
          id: '5ed75dc0-f0be-4be6-9e30-0ffa101eae21',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D318 120 Boston Brown Fantasiestreep Essentials - Beige & Brown 21',
        },
        {
          id: 'dae0a8ee-8dde-4089-bf1a-84b78c5c8134',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D319 120 Pencil Dark Grey Fantasiestreep Graphic Design Selection 6',
        },
        {
          id: '7767d07c-dc45-4385-ba76-98d4c93ce1e9',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D320 120 Pencil Grey Fantasiestreep Graphic Design Selection 6',
        },
        {
          id: 'baba7b48-d6ce-406e-b19a-2458f1d1d308',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D321 120 Pencil Blue Fantasiestreep Graphic Design Selection 6',
        },
        {
          id: 'da654af3-1193-4aee-8467-7ad5ad8d977d',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D322 120 Pencil Green Fantasiestreep Graphic Design Selection 9',
        },
        {
          id: '3f40e8a1-df6b-40b6-b64d-6aaf04a906ec',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D323 120 Pencil Yellow Fantasiestreep Graphic Design Selection 9',
        },
        {
          id: '95e2cf5d-7dfa-4cfc-83bf-498f2b47178e',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D324 120 Pencil Beige Fantasiestreep Graphic Design Selection 6',
        },
        {
          id: 'd9b0e22d-828a-4e78-a732-4be509aa1378',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D325 120 Craft Dark Grey Fantasiestreep Essentials - Grey 19',
        },
        {
          id: 'f6abc2a7-6d0d-4c87-a6c1-9f638c08e240',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D326 120 Craft Beige Fantasiestreep Color Design Selection 13',
        },
        {
          id: '51932cf7-83d4-4e6c-bc4a-08b7abdbba32',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D327 120 Craft Blue Fantasiestreep Color Design Selection 14',
        },
        {
          id: '4ee13d32-23cc-4c57-aa3a-040371fef875',
          product: null,
          overwrittenPrice: null,
          title: 'ORC D328 120 Craft Grey Fantasiestreep Essentials - Grey 18',
        },
        {
          id: 'b54782ea-27bb-4251-8790-7629db2005ed',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D329 120 Craft Red Fantasiestreep Color Design Selection 13',
        },
        {
          id: '1c10c4ad-895c-49db-97a1-c2135c65f68a',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D330 120 Color Bloc Black Fantasiestreep Essentials - Grey 19',
        },
        {
          id: 'a949d069-7243-4eed-a50b-80485313d625',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D331 120 Color Bloc Blue Fantasiestreep Color Design Selection 14',
        },
        {
          id: '570a794d-348a-45fe-892f-c14cd63e8ebd',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D332 120 Color Bloc Orange Fantasiestreep Color Design Selection 13',
        },
        {
          id: '8a93d443-964f-44d9-9d74-b8baa611b038',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D333 120 Color Bloc Yellow Fantasiestreep Color Design Selection 13',
        },
        {
          id: '3357817a-5762-48f7-ad11-3b3dd89a7124',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D334 120 Color Bloc Brown Fantasiestreep Essentials - Beige & Brown 21',
        },
        {
          id: '4bb8cb80-d53e-4360-8fc7-107b16673f14',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D335 120 Color Bloc Red Fantasiestreep Color Design Selection 13',
        },
        {
          id: 'abc20389-0980-49af-b462-07648a526f3a',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D337 120 Manosque Blue Fantasiestreep Essentials - Blue 26',
        },
        {
          id: '03729948-7251-45fa-b372-3539b62d93c7',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC D338 120 Hardelot Blue Fantasiestreep Essentials - Blue 27',
        },
        {
          id: '68e6a81c-c362-4725-8b44-bf030da6973c',
          product: null,
          overwrittenPrice: null,
          title: 'ORC D339 120 Rome Blue Fantasiestreep Essentials - Blue 27',
        },
        {
          id: 'd2d513de-13e6-4f70-9f30-4a7515df61ca',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U083 120 Chocolat Uni - Effen Essentials - Beige & Brown 21',
        },
        {
          id: '3dcb50f4-4811-44b5-a2ba-dfceea5dc32c',
          product: null,
          overwrittenPrice: null,
          title: 'ORC U095 120 Basalte Chiné Uni - Chiné Essentials - Grey 19',
        },
        {
          id: 'c2e1a668-58c3-4d17-a4e6-b5e28e1dcab6',
          product: null,
          overwrittenPrice: null,
          title: 'ORC U104 120 Flanelle Chiné Uni - Chiné Essentials - Grey 19',
        },
        {
          id: '12c11592-0cda-45b0-8422-b9f9f0e29f72',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U105 120 Curry Chiné Uni - Chiné Color Design Selection 13',
        },
        {
          id: '30bcf008-9921-4a30-94fb-29e0f19a1bab',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U136 120 Albatre Tweed Uni - Tweed Essentials - Yellow & Orange 23',
        },
        {
          id: '8cee74da-6238-4b20-a17d-782f67fe22f5',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U137 120 Vison Tweed Uni - Tweed Graphic Design Selection 9',
        },
        {
          id: 'dd104a98-36a5-487f-b88f-32e2117cf57b',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U140 120 Gazelle Tweed Uni - Tweed Graphic Design Selection 9',
        },
        {
          id: '54c2e9bd-e7a6-412d-aa78-89c062d6cba7',
          product: null,
          overwrittenPrice: null,
          title: 'ORC U170 120 Pink Uni - Effen Essentials - Red & Pink 25',
        },
        {
          id: '455502a1-c448-4629-a62f-fdbbe6ab40c0',
          product: null,
          overwrittenPrice: null,
          title: 'ORC U171 120 Carbone Uni - Effen Essentials - Grey 19',
        },
        {
          id: 'b9927d5c-6e63-4306-a685-6ac2bd92e53b',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U189 120 Beige Tweed Uni - Tweed Graphic Design Selection 9',
        },
        {
          id: '438fe84b-5999-40fe-a68e-2e1b76dc46c4',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U190 120 Gris Tweed Uni - Tweed Graphic Design Selection 8',
        },
        {
          id: 'c72c90a1-e988-4eaf-abbd-57bd27a72933',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U224 120 Brownie Uni - Effen Essentials - Beige & Brown 21',
        },
        {
          id: '3bd15dbc-bb24-408a-a08f-0473c67b6b45',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U235 120 Chaume Piqué Uni - Piqué Essentials - Beige & Brown 21',
        },
        {
          id: '27cfe2de-22ae-4714-9d34-a5108976f4d7',
          product: null,
          overwrittenPrice: null,
          title: 'ORC U320 120 Canard Chiné Uni - Chiné Essentials - Green 29',
        },
        {
          id: 'f30b2cf5-c7e3-4f16-80ab-32af95e78595',
          product: null,
          overwrittenPrice: null,
          title: 'ORC U321 120 Granny Chiné Uni - Chiné Essentials - Green 29',
        },
        {
          id: '9d1c84f3-63e5-4b9b-84c1-681cf14abf5c',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U335 120 Gypse Piqué Uni - Piqué Graphic Design Selection 9',
        },
        {
          id: 'ae5bd958-0368-44dc-a71f-54878f6fb532',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U337 120 Daim Piqué Uni - Piqué Color Design Selection 13',
        },
        {
          id: 'ee90ef56-bfcb-44ac-8174-2eeeda092c55',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U343 120 Poivre Piqué Uni - Piqué Color Design Selection 14',
        },
        {
          id: '30a286c3-bff5-46d6-9e4d-2822e0ed3211',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U370 120 Papyrus Tweed Uni - Tweed Graphic Design Selection 9',
        },
        {
          id: '769546e5-b4ae-4861-832b-32c2e52fffe5',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U371 120 Chamois Tweed Uni - Tweed Graphic Design Selection 9',
        },
        {
          id: '19261dd3-1930-43cb-9f56-b0f6e8f7a44e',
          product: null,
          overwrittenPrice: null,
          title: 'ORC U373 120 Macadam Tweed Uni - Tweed Essentials - Grey 19',
        },
        {
          id: 'd27a027c-1cbc-4bcb-bc70-4a5a2854674e',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U387 120 Argile Uni - Effen Essentials - Beige & Brown 20',
        },
        {
          id: '6fedd235-2b1e-4e09-a93a-7d77c9ecde25',
          product: null,
          overwrittenPrice: null,
          title: 'ORC U388 120 Azur Uni - Effen Essentials - Blue 27',
        },
        {
          id: '632a872e-ca84-4a67-9fb0-6192ad70d468',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U389 120 Cérusé Tweed Uni - Tweed Graphic Design Selection 8',
        },
        {
          id: '228bfb66-5a6d-43da-b53b-94cc52c9f597',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U390 120 Switch Rouge Uni - Switch Color Design Selection 13',
        },
        {
          id: 'e31f66bb-f7c9-493d-9bf2-9885d479058b',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U391 120 Switch Océan Uni - Switch Color Design Selection 14',
        },
        {
          id: '49f480c5-3da8-44f9-8009-e99c8b78e3cf',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U393 120 Switch Empire Uni - Switch Color Design Selection 14',
        },
        {
          id: 'e9dddfa3-54bd-4ba8-ae15-fe12ac90d138',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U394 120 Switch Vermillon Uni - Switch Color Design Selection 13',
        },
        {
          id: '40e6bd12-e5c7-42ea-a7a8-b924e1c6b572',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U395 120 Switch Vert Uni - Switch Color Design Selection 14',
        },
        {
          id: '5f9c26f1-2029-459f-984f-7843bc439e5e',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U396 120 Switch Orange Uni - Switch Color Design Selection 13',
        },
        {
          id: '3da40ddf-3254-476c-b4b2-6304179beb9b',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U397 120 Switch Curcuma Uni - Switch Color Design Selection 13',
        },
        {
          id: '1db28c35-11a4-4db8-b6b4-c3d49fb1d7e7',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U398 120 Switch Moutarde Uni - Switch Color Design Selection 13',
        },
        {
          id: '37b2cdd7-db49-4ff3-babc-1769dd841f81',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U399 120 Switch Corail Uni - Switch Color Design Selection 13',
        },
        {
          id: '96c8eb93-44e5-45a9-829c-bdc08d6e8188',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U400 120 Switch Turquoise Uni - Switch Graphic Design Selection 6',
        },
        {
          id: 'eab7b958-260b-497d-96e9-e39391ef7cf9',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U401 120 Switch Pink Uni - Switch Color Design Selection 13',
        },
        {
          id: '11c99f8c-4e7b-49ec-aad8-247cbf59c0dd',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U402 120 Switch Chardon Uni - Switch Color Design Selection 14',
        },
        {
          id: '9d5ca041-2f39-4c75-9d08-71aa550c171b',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U403 120 Switch Mandarine Uni - Switch Color Design Selection 13',
        },
        {
          id: 'ce33ebea-446e-4e41-889b-bb8d6fab9f05',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U404 120 Switch Citron Uni - Switch Graphic Design Selection 9',
        },
        {
          id: '24342b5d-8815-4ae5-a1f5-2930b0d1dea3',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U406 120 Acier Piqué Uni - Piqué Graphic Design Selection 6',
        },
        {
          id: '137b059f-2616-4c11-82d0-72c97c76de91',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U407 120 Platine Piqué Uni - Piqué Graphic Design Selection 6',
        },
        {
          id: '4772f02b-ab53-4ad9-b70b-2e51bcd24f5c',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U408 120 Titane Piqué Uni - Piqué Graphic Design Selection 6',
        },
        {
          id: 'b6f308f6-2992-4cee-8dda-9ee7f8f385da',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U409 120 Marbre Piqué Uni - Piqué Graphic Design Selection 9',
        },
        {
          id: '19f75de2-f2a3-4c09-a4cc-f0b64e632e8c',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U410 120 Moka Piqué Uni - Piqué Color Design Selection 13',
        },
        {
          id: '524b8298-90d0-4f99-a096-df6d961f4940',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U411 120 Carmin Piqué Uni - Piqué Color Design Selection 13',
        },
        {
          id: '61e24f2a-acf3-4692-8ddf-383584a98d30',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U412 120 Piment Piqué Uni - Piqué Color Design Selection 13',
        },
        {
          id: '5f537b8c-86a3-4a75-aa33-067e5d231303',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U413 120 Potiron Piqué Uni - Piqué Color Design Selection 13',
        },
        {
          id: '409e2eba-cbac-4b8d-a2a5-d56cb1975445',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U414 120 Miel Piqué Uni - Piqué Color Design Selection 13',
        },
        {
          id: 'd92f4843-bebc-4dca-853b-899d804225b2',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U415 120 Maïs Piqué Uni - Piqué Color Design Selection 13',
        },
        {
          id: '75066e7a-e427-45dc-9ef3-fa03c3dbc250',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U416 120 Lichen Piqué Uni - Piqué Color Design Selection 14',
        },
        {
          id: '47164a98-cf28-48c5-a3b4-7b89a200df7c',
          product: null,
          overwrittenPrice: null,
          title:
            'ORC U417 120 Mélèze Piqué Uni - Piqué Color Design Selection 14',
        },
        {
          id: 'add0ac57-f73d-4714-ab9d-70d885150293',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 0001 120 Ecru Uni - Effen Orchestra Max 32',
        },
        {
          id: '7f0eb5ef-a6fb-4ff9-9b0d-2cef709dff2d',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 0681 120 Dune Uni - Effen Orchestra Max 33',
        },
        {
          id: '96916daa-da27-4edf-9a6f-48f321d6fa50',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 3914 120 Rouge Uni - Effen Orchestra Max 33',
        },
        {
          id: 'ae2cfdf3-2703-41bd-b615-c5dd32b1ff89',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 6020 120 Grège Uni - Effen Orchestra Max 33',
        },
        {
          id: '90ff19e2-a14a-42bd-bded-1580b5605a57',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 6028 120 Noir Uni - Effen Orchestra Max 33',
        },
        {
          id: 'e7964de6-9aef-47d9-b7cd-1518a6aa2083',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 6088 120 Gris Uni - Effen Orchestra Max 32',
        },
        {
          id: 'ac861c57-7432-4ffd-a7be-f36ff18d7ad3',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 6196 120 Pierre Uni - Effen Orchestra Max 32',
        },
        {
          id: '5b87fa44-4621-4479-a44d-ee45ce114c7f',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 6687 120 Forêt Uni - Effen Orchestra Max 33',
        },
        {
          id: '1d9cacd9-0eed-44d4-964b-fae0479407eb',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 7133 120 Naturel Uni - Effen Orchestra Max 33',
        },
        {
          id: '8a382dd5-48b1-4668-bca1-d36f8a0291d0',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 7264 120 Océan Uni - Effen Orchestra Max 33',
        },
        {
          id: 'add7a37b-ee6e-42e7-8da8-fb5cdcd489fa',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 7548 120 Ivoire Uni - Effen Orchestra Max 33',
        },
        {
          id: '12e57383-1f44-4739-9d61-f3e8760082f5',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 7559 120 Taupe Uni - Effen Orchestra Max 33',
        },
        {
          id: '03c16aec-ee83-425b-a705-15b90b4f669b',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 8203 120 Ardoise Uni - Effen Orchestra Max 33',
        },
        {
          id: '9ee6b098-5025-4f69-b040-9e3b3ec6db5c',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 8206 120 Bordeaux Uni - Effen Orchestra Max 33',
        },
        {
          id: '4f4bd106-8109-47e6-a8c9-9497d623b328',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 8211 120 Sienne Red Fantasiestreep Orchestra Max 33',
        },
        {
          id: '90ab6cac-e0be-4283-a7fb-367bec1f597e',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 8396 120 Souris Chiné Uni - Chiné Orchestra Max 32',
        },
        {
          id: '4d1ade7c-6a40-42ae-8d4d-26a1640ca614',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 8779 120 Bruyère Chiné Uni - Chiné Orchestra Max 33',
        },
        {
          id: 'f82ffadd-f89e-4805-b1eb-2d76d873c303',
          product: null,
          overwrittenPrice: null,
          title: 'MAX 8891 120 Toast Uni - Effen Orchestra Max 33',
        },
        {
          id: 'a4e93183-0bd6-4dec-b27d-979bd31b9627',
          product: null,
          overwrittenPrice: null,
          title:
            'MAX 8931 120 Sienne Dark Grey Fantasiestreep Orchestra Max 33',
        },
        {
          id: '9abeafdc-71f6-4cf2-b865-3c13f5fc24ac',
          product: null,
          overwrittenPrice: null,
          title: 'MAX D113 120 Naples Grey Fantasiestreep Orchestra Max 33',
        },
        {
          id: '0d30d951-7549-4926-b1ee-5475c73d5544',
          product: null,
          overwrittenPrice: null,
          title: 'MAX D307 120 Naples Linen Fantasiestreep Orchestra Max 33',
        },
        {
          id: '398bc254-136d-4abf-b90b-9833bbc73a4c',
          product: null,
          overwrittenPrice: null,
          title:
            'MAX D308 120 Naples Dark Grey Fantasiestreep Orchestra Max 33',
        },
        {
          id: '6ad7d1ca-3fc3-4210-bb24-ab52e8cddfa7',
          product: null,
          overwrittenPrice: null,
          title: 'MAX U104 120 Flanelle Chiné Uni - Chiné Orchestra Max 33',
        },
        {
          id: '0e4e4bee-8c9d-4897-8347-4410243895a7',
          product: null,
          overwrittenPrice: null,
          title: 'MAX U171 120 Carbone Uni - Effen Orchestra Max 33',
        },
        {
          id: '1f7bfdf4-cad4-4c76-86bd-1564d67379dd',
          product: null,
          overwrittenPrice: null,
          title: 'MAX U224 120 Brownie Uni - Effen Orchestra Max 33',
        },
        {
          id: 'f7398c17-386d-41fd-9ce8-caa78f79a4e6',
          product: null,
          overwrittenPrice: null,
          title: 'MAX U387 120 Argile Uni - Effen Orchestra Max 33',
        },
        {
          id: '331eb56c-0956-4e4d-b4f0-4e5c1035ad1a',
          product: null,
          overwrittenPrice: null,
          title: 'MAX U409 120 Marbre Piqué Uni - Piqué Orchestra Max 32',
        },
        {
          id: 'b4ffe0c2-29a1-4ee9-9b68-8f6f1ca25ab2',
          product: null,
          overwrittenPrice: null,
          title: 'MAX U413 120 Potiron Piqué Uni - Piqué Orchestra Max 33',
        },
        {
          id: '0d013910-829c-4ae1-8855-78b5fead3304',
          product: null,
          overwrittenPrice: null,
          title: 'MAX U415 120 Maïs Piqué Uni - Piqué Orchestra Max 33',
        },
        {
          id: '4fd9a626-1f84-4083-a633-7683c4b28e7d',
          product: null,
          overwrittenPrice: null,
          title: 'MAX U416 120 Lichen Piqué Uni - Piqué Orchestra Max 33',
        },
        {
          id: 'fcce1533-cd3d-45be-94ee-4c4cd9096c87',
          product: null,
          overwrittenPrice: null,
          title: 'OPE J062 120 Galet Grey Jacquard Essentials - Grey 18',
        },
        {
          id: 'd87042c4-ccb6-4ced-83fb-eedc724a1833',
          product: null,
          overwrittenPrice: null,
          title: 'OPE J169 120 Brush Beige Jacquard Graphic Design Selection 9',
        },
        {
          id: '9804e9cb-1b20-4b4c-9fc0-9dc4746bfd7f',
          product: null,
          overwrittenPrice: null,
          title: 'OPE J170 120 Brush Hemp Jacquard Graphic Design Selection 9',
        },
        {
          id: 'f3c84264-f12d-4d6b-9ce8-5cc6649a04e7',
          product: null,
          overwrittenPrice: null,
          title: 'OPE J171 120 Brush Grey Jacquard Graphic Design Selection 8',
        },
        {
          id: '25f0f00f-6c38-43da-b12f-63ce83000071',
          product: null,
          overwrittenPrice: null,
          title:
            'OPE J172 120 Brush Carbon Jacquard Graphic Design Selection 6',
        },
        {
          id: '0a583635-7a82-46ca-a739-bd8ce1b743cb',
          product: null,
          overwrittenPrice: null,
          title:
            'OPE J177 120 Constellation Curry Jacquard Graphic Design Selection 9',
        },
        {
          id: '62ec9d45-062a-430f-9322-8de698da427c',
          product: null,
          overwrittenPrice: null,
          title:
            'OPE J178 120 Constellation Grey Jacquard Graphic Design Selection 6',
        },
        {
          id: '8885de34-a077-4e9b-80d4-141b0968ce00',
          product: null,
          overwrittenPrice: null,
          title:
            'OPE J179 120 Constellation Beige Jacquard Graphic Design Selection 8',
        },
        {
          id: 'a8a97b20-661e-4d7f-8fed-70533dcd3fb3',
          product: null,
          overwrittenPrice: null,
          title:
            'OPE J180 120 Constellation Blue Jacquard Graphic Design Selection 6',
        },
      ],
      overwrittenPrice: null,
      title: 'Doek ',
      isRequired: false,
      sharedKey: 'FOK16_DOEK',
      initialValue: null,
      resourcetype: 'ProductOptionChoice',
    },
    {
      id: '29fda03d-a93d-4df2-a77b-8613e89e498d',
      type: 'productoptionchoice',
      items: [
        {
          id: '0b9cc6ae-d05b-4160-97ec-a2d5ebba2aa1',
          product: null,
          overwrittenPrice: null,
          title: 'Somfy IO motor met afstandsbediening',
        },
        {
          id: 'eec4a09b-2bee-4081-a4ea-0b53c72626bf',
          product: null,
          overwrittenPrice: null,
          title: 'Somfy RTS met afstandsbediening',
        },
        {
          id: 'b225a83d-7f4b-45da-9ab1-a386aeab138b',
          product: null,
          overwrittenPrice: null,
          title: 'Somfy WT motor met wandschakelaar',
        },
        {
          id: '732242a3-c41f-4074-9491-ff7cca54552c',
          product: null,
          overwrittenPrice: null,
          title: 'Handbediend (max. 4000x2500)',
        },
      ],
      overwrittenPrice: null,
      title: 'Type bediening',
      isRequired: false,
      sharedKey: 'FOK16_BEDT',
      initialValue: null,
      resourcetype: 'ProductOptionChoice',
    },
    {
      id: '491005b8-b4f3-4f9c-a740-1acdd0fc1760',
      type: 'productoptionchoice',
      items: [
        {
          id: 'fae1feaa-fd47-4d78-bbac-8125447746ec',
          product: null,
          overwrittenPrice: null,
          title: 'rechts of links',
        },
        {
          id: 'b2f40c2d-7207-47b0-902f-113c00f13ac6',
          product: null,
          overwrittenPrice: null,
          title: 'rechts  ',
        },
        {
          id: 'f00143a8-223b-456d-b8d9-7f5ffa3592ef',
          product: null,
          overwrittenPrice: null,
          title: 'links  ',
        },
      ],
      overwrittenPrice: null,
      title: 'Bedieningszijde (van buiten gezien)',
      isRequired: false,
      sharedKey: 'FOK16_BEDZ',
      initialValue: null,
      resourcetype: 'ProductOptionChoice',
    },
    {
      id: 'a8dfb1b0-4bbb-4a8f-a048-16caa6ee3073',
      type: 'productoptionchoice',
      items: [
        {
          id: 'b58813b7-2b98-4ec7-8e28-dec271e889b0',
          product: null,
          overwrittenPrice: null,
          title:
            'Exclusief volant - strook textiel onderaan de voorzijde van het zonnescherm',
        },
        {
          id: '19a2d9c7-5dd2-4500-b0c6-b44081ccfdc7',
          product: null,
          overwrittenPrice: null,
          title: 'Volant Tibelly collectie gelijk aan het scherm',
        },
        {
          id: 'f4e9ef5b-09a8-4db7-93da-9506e3acd4d4',
          product: null,
          overwrittenPrice: null,
          title: 'Volant Dickson Orchestra collectie gelijk aan het scherm',
        },
        {
          id: '8056c041-b8ee-4a96-85c1-d65193b08d12',
          product: null,
          overwrittenPrice: null,
          title: 'Volant Dickson Max collectie gelijk aan het scherm',
        },
        {
          id: 'd139f01e-a658-4e32-8e0e-8f31af190101',
          product: null,
          overwrittenPrice: null,
          title: 'Volant Dickson Jacquard collectie gelijk aan het scherm',
        },
      ],
      overwrittenPrice: null,
      title: 'Volant',
      isRequired: false,
      sharedKey: 'FOK16_VOLA',
      initialValue: null,
      resourcetype: 'ProductOptionChoice',
    },
    {
      id: 'a459f887-c8e6-4a49-b310-ccc5a313f4a1',
      type: 'productoptiontoggle',
      product: null,
      overwrittenPrice: null,
      title: 'Montage',
      initialValue: true,
      isRequired: false,
      resourcetype: 'ProductOptionToggle',
    },
    {
      id: 'd1b17bcf-9046-4ca0-96c7-dfa43d9a23dd',
      type: 'productoptiontoggle',
      product: null,
      overwrittenPrice: null,
      title: '10m motorkabel',
      initialValue: false,
      isRequired: false,
      resourcetype: 'ProductOptionToggle',
    },
    {
      id: '3fee23b9-2c1e-4c52-9248-c1025b3202b0',
      type: 'productoptiontoggle',
      product: null,
      overwrittenPrice: null,
      title: 'Demontage bestaande zonwering',
      initialValue: false,
      isRequired: false,
      resourcetype: 'ProductOptionToggle',
    },
    {
      id: 'ab96e908-0c71-4f45-8885-94718e72ad6b',
      type: 'productoptiontoggle',
      product: null,
      overwrittenPrice: null,
      title: 'Chemische ankers in de steen',
      initialValue: false,
      isRequired: false,
      resourcetype: 'ProductOptionToggle',
    },
    {
      id: '8eac3f0b-e679-4d29-a0d3-82bd3d3a71e0',
      type: 'productoptiontoggle',
      product: null,
      overwrittenPrice: null,
      title: 'Chemische ankers in verdiepingsvloer ',
      initialValue: false,
      isRequired: false,
      resourcetype: 'ProductOptionToggle',
    },
    {
      id: 'd022d8bd-22b2-4100-ae0b-cf305b5d02d7',
      type: 'productoptiontoggle',
      product: null,
      overwrittenPrice: null,
      title: '2 Speciale hoekbeugels ivm boeideel',
      initialValue: false,
      isRequired: false,
      resourcetype: 'ProductOptionToggle',
    },
    {
      id: 'f1ea65c8-80e9-4520-829f-fc6bf03e214f',
      type: 'productoptiontoggle',
      product: null,
      overwrittenPrice: null,
      title: '2 Plafond steunen (niet in RAL 7016 str)',
      initialValue: false,
      isRequired: false,
      resourcetype: 'ProductOptionToggle',
    },
    {
      id: '2d251333-a699-424b-a785-d204fecd2ddc',
      type: 'productoptiontoggle',
      product: null,
      overwrittenPrice: null,
      title: '2 Verlengde steunen (55cm)',
      initialValue: false,
      isRequired: false,
      resourcetype: 'ProductOptionToggle',
    },
    {
      id: 'a2339bc2-f603-4663-95bd-11508238f0b3',
      type: 'productoptiontoggle',
      product: null,
      overwrittenPrice: null,
      title: '2 Verlengde steunen (85cm)',
      initialValue: false,
      isRequired: false,
      resourcetype: 'ProductOptionToggle',
    },
  ],
};
