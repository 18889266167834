import { filter } from 'rxjs/operators';
import { ActionCreator, AnyAction, Action } from 'typescript-fsa';
import { OperatorFunction, Observable } from 'rxjs';

export function ofAction<P>(
  ac: ActionCreator<P>
): OperatorFunction<AnyAction, Action<P>>;
export function ofAction<P1, P2>(
  ac1: ActionCreator<P1>,
  ac2: ActionCreator<P2>
): OperatorFunction<AnyAction, Action<P1 & P2>>;
export function ofAction<P1, P2, P3>(
  ac1: ActionCreator<P1>,
  ac2: ActionCreator<P2>,
  ac3: ActionCreator<P3>
): OperatorFunction<AnyAction, Action<P1 & P2 & P3>>;
export function ofAction<P1, P2, P3, P4>(
  ac1: ActionCreator<P1>,
  ac2: ActionCreator<P2>,
  ac3: ActionCreator<P3>,
  ac4: ActionCreator<P4>
): OperatorFunction<AnyAction, Action<P1 & P2 & P3 & P4>>;
export function ofAction<P1, P2, P3, P4, P5>(
  ac1: ActionCreator<P1>,
  ac2: ActionCreator<P2>,
  ac3: ActionCreator<P3>,
  ac4: ActionCreator<P4>,
  ac5: ActionCreator<P5>
): OperatorFunction<AnyAction, Action<P1 & P2 & P3 & P4 & P5>>;
export function ofAction(...actionCreators: Array<ActionCreator<{} | void>>) {
  return (source: Observable<AnyAction>): Observable<AnyAction> =>
    source.pipe(
      filter((action: AnyAction) =>
        actionCreators.reduce(
          (acc, actionCreator) => acc || actionCreator.match(action),
          false
        )
      )
    );
}
