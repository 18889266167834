import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { OrderedSet, Map } from 'immutable';

import * as actions from './actions';
import {
  ChoiceOption,
  ChoiceOptionItem,
  IAPIProductKind,
  IAPIProduct,
  Product,
  ToggleOption,
  Variant,
  ProductKind,
} from '@vitrona/api/catalogue';

export const reducerPath = 'catalogue';

export class CatalogueState extends Record({
  tree: Map(),
  items: Map(),
}) {}

// @TODO see if it's viable create a "higher order parser"
function parseProductKinds(state: CatalogueState, items: IAPIProductKind[]) {
  return state.withMutations((next) => {
    for (const item of items) {
      next.updateIn(['tree', item.id], (list = OrderedSet()) => list);
      next.updateIn(['tree', 'byProductKind'], (list = OrderedSet()) =>
        list.add(item.id)
      );
      next.setIn(['items', item.id], ProductKind.fromAPI(item));
    }
  });
}

function parseProduct(state: CatalogueState, item: IAPIProduct) {
  return state.withMutations((next) => {
    const { productVariants = [], options = [] } = item;
    const product = Product.fromAPI(item);

    next.setIn(['items', item.id], product);

    next.updateIn(['tree', item.productKind], (list = OrderedSet()) =>
      list.add(item.id)
    );

    for (const variantRaw of productVariants) {
      const variant = Variant.fromAPI(variantRaw, {
        dimensions: product.properties.dimensions,
      });

      next.setIn(['items', variant.id], variant);
    }

    for (const optionRaw of options) {
      if (optionRaw.resourcetype === 'ProductOptionToggle') {
        const option = ToggleOption.fromAPI(optionRaw);
        next.setIn(['items', option.id], option);
      }

      if (optionRaw.resourcetype === 'ProductOptionChoice') {
        const option = ChoiceOption.fromAPI(optionRaw);
        next.setIn(['items', option.id], option);

        for (const itemRaw of optionRaw.items) {
          const item = ChoiceOptionItem.fromAPI(itemRaw);
          next.setIn(['items', item.id], item);
        }
      }
    }
  });
}

function parseProducts(state: CatalogueState, items: IAPIProduct[]) {
  return state.withMutations((next) => {
    for (const item of items) {
      parseProduct(next, item);
    }
  });
}

export const initialState = new CatalogueState();

export const reducer = reducerWithInitialState(initialState)
  // prettier-ignore
  .case(actions.fetchProductKindsAsync.done, (state, payload) => {
    return parseProductKinds(state, payload.result);
  })
  .case(actions.fetchProductsAsync.done, (state, payload) =>
    parseProducts(state, payload.result)
  )
  .case(actions.fetchProductAsync.done, (state, { result }) => {
    return parseProducts(state, [result]);
  });
