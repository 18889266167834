import React from 'react';
import env from '@vitrona/env';

import './ExploreContainer.css';

interface ContainerProps {
  name: string;
}

export const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {
  return (
    <div className="container">
      <h1>Welcome to app!</h1>
      <strong>{name}</strong>
      <p>
        Explore{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://ionicframework.com/docs/components"
        >
          UI Components
        </a>
      </p>

      <p>API_ROOT = {`${env.get<string>('API_ROOT')}`}</p>
    </div>
  );
};
