import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { OrderedSet, Map } from 'immutable';

import * as actions from './actions';
import {
  IAPIOffer,
  IAPIOfferLine,
  IAPIOfferLineDetail,
  Offer,
  OfferLine,
  OfferUpdateProps,
  Option,
} from '@vitrona/api/offer';

export const reducerPath = 'offers';

enum LineStatus {
  Inactive = 'inactive',
  Active = 'active',
  Loading = 'loading',
  Failed = 'failed',
  Saving = 'saving',
}

interface IActiveLineProps {
  id?: string;
  status: LineStatus;
}

const defaultActiveLineProps: IActiveLineProps = {
  id: '',
  status: LineStatus.Inactive,
};

class ActiveLine extends Record(defaultActiveLineProps) {
  public static of(props = defaultActiveLineProps) {
    return new ActiveLine(props);
  }
}

export class OffersState extends Record({
  activeOffer: '',
  tree: Map(),
  items: Map(),

  activeLine: ActiveLine.of(),
}) {}

function parseOffer(state: OffersState, item: IAPIOffer) {
  return state.withMutations((next) => {
    const offer = Offer.fromAPI(item);

    // @TODO add case when the activeOffer needs te be cleared
    // @NOTE for now newly created offers are by default the active offer
    next.set('activeOffer', offer.id);

    next.updateIn(['tree', offer.id], (list = OrderedSet()) => list);
    next.setIn(['items', offer.id], offer);
  });
}

function parseOfferLine(state: OffersState, item: IAPIOfferLine) {
  return state.withMutations((next) => {
    const line = OfferLine.fromAPI(item);

    next.updateIn(['tree', line.offerId], (list = OrderedSet()) =>
      list.add(item.id)
    );
    next.updateIn(['tree', line.id], (list = OrderedSet()) => list);
    next.setIn(['items', line.id], line);
  });
}

function parseOfferLineDetail(state: OffersState, item: IAPIOfferLineDetail) {
  return state.withMutations((next) => {
    const line = OfferLine.fromDetailAPI(item);

    for (const subOfferLine of item.subOfferLines) {
      const option = Option.fromAPI(subOfferLine);
      next.setIn(['items', option.id], option);
    }

    next.updateIn(['tree', line.offerId], (list = OrderedSet()) =>
      list.add(item.id)
    );
    next.updateIn(['tree', line.id], (list = OrderedSet()) => list);
    next.setIn(['items', line.id], line);
  });
}

function merge(item: Offer, updates: OfferUpdateProps) {
  return item.mergeDeep(Offer.updates(updates));
}

export const initialState = new OffersState();

export const reducer = reducerWithInitialState(initialState)
  .case(actions.createOfferLine, (state) =>
    state.setIn(['activeLine'], ActiveLine.of({ status: LineStatus.Loading }))
  )

  .case(actions.cancelOfferLineEdit, (state) =>
    state.set('activeLine', ActiveLine.of())
  )
  .case(actions.editOfferLine, (state, { id }) =>
    state.set('activeLine', ActiveLine.of({ id, status: LineStatus.Active }))
  )

  .case(actions.createOfferLineAsync.done, (state, { result }) =>
    parseOfferLine(state, result)
      // prettier-ignore
      .update('activeLine', (line) =>
        // prettier-ignore
        line
            .set('id', result.id)
            .set('status', LineStatus.Active)
      )
  )

  .case(actions.fetchOfferLineAsync.done, (state, { result }) =>
    parseOfferLineDetail(state, result)
  )

  .case(actions.saveOfferLine, (state) =>
    state.setIn(['activeLine', 'status'], LineStatus.Saving)
  )
  .case(actions.saveOfferLineAsync.done, (state, { result }) =>
    state
      .setIn(['activeLine', 'status'], LineStatus.Inactive)
      .withMutations((next) => parseOfferLineDetail(next, result))
  )

  .case(actions.saveOfferLineOptionsAsync.done, (state, { result }) =>
    state.withMutations((next) => {
      for (const subOfferLine of result) {
        const option = Option.fromAPI(subOfferLine);
        next.setIn(['items', option.id], option);
      }
    })
  )

  .case(
    actions.removeOfferLineAsync.done,
    (state, { result: { id, offerId } }) => {
      return state.withMutations((next) => {
        next.updateIn(['tree', offerId], (list: OrderedSet<string>) =>
          list.filter((childId) => childId !== id)
        );
        next.deleteIn(['items', id]);
      });
    }
  )

  .case(actions.abortOfferAsync.done, (state) => state.set('activeOffer', ''))

  .case(actions.createOfferAsync.done, (state, { result }) =>
    parseOffer(state, result)
  )

  .case(actions.saveOfferAsync.done, (state, { result }) =>
    state.updateIn(['items', result.id], (item) => merge(item, result))
  );
