import React from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
} from '@ionic/react';
import { closeCircleOutline as closeIcon } from 'ionicons/icons';

import { LegacyModal } from '@vitrona/ui';

const fields = [
  {
    label: 'Naam',
    name: 'name',
    defaultValue: 'Jan de Boer',
    required: true,
    type: 'text/name',
  },
  {
    label: 'Organisatie',
    name: 'organization',
    defaultValue: '',
    placeholder: 'Naam organisatie',
    type: 'text/organisation',
  },
  {
    label: 'Straat + Huisnummer',
    name: 'street',
    defaultValue: 'Gaardedreef 187',
    type: 'text/street',
    required: true,
  },
  {
    label: 'Postcode',
    name: 'postalcode',
    defaultValue: '2723 AZ',
    required: true,
    type: 'text/postalcode',
  },
  {
    label: 'Telefoonnummer',
    name: 'phone',
    defaultValue: '06 1700 48 51',
    required: true,
    type: 'text/phone',
  },
  {
    label: 'E-mail',
    name: 'email',
    defaultValue: 'contact@fluxility.com',
    type: 'text/email',
    required: true,
  },
  {
    label: 'Opmerkingen',
    name: 'remarks',
    defaultValue: '2ᵉ verdieping',
    type: 'text/multiline',
  },
];

export const clientData = fields.reduce(
  (acc, field) => {
    acc.items[field.name] = {
      label: field.label,
      value: field.defaultValue,
      formattedValue: field.defaultValue,
    };
    acc.result.push(field.name);

    return acc;
  },
  {
    result: [],
    items: {},
  }
);

function getSubTypeForInput(type: string) {
  switch (type) {
    case 'text/email':
      return 'email';
    default:
      return 'text';
  }
}

function FieldWidget({ type, defaultValue, placeholder = '', required }) {
  if (type === 'text/multiline') {
    return (
      <IonTextarea
        placeholder={placeholder}
        required={required}
        value={defaultValue}
      ></IonTextarea>
    );
  }

  if (type.includes('text')) {
    return (
      <IonInput
        required={required}
        placeholder={placeholder}
        type={getSubTypeForInput(type)}
        value={defaultValue}
      ></IonInput>
    );
  }

  return null;
}

// @TODO move
export function ClientDetails({
  isOpen = false,
  onDidDismiss,
  children = null,
  modalRef,
  presentingElement,
}: {
  isOpen?: boolean;
  onDidDismiss: () => void;
  children?: React.ReactNode;
}) {
  return (
    <LegacyModal
      modalRef={modalRef}
      presentingElement={presentingElement}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <IonPage>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>Offerte - klantgegevens</IonTitle>

            <IonButtons slot="secondary">
              <IonButton onClick={onDidDismiss}>
                <IonIcon slot="end" icon={closeIcon} />
                Sluiten
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList>
            {fields.map((f, i) => (
              <IonItem key={`${f.label}-${i}`}>
                <IonLabel position="stacked">
                  {f.label} <span>{f.required ? '' : '(optioneel)'}</span>
                </IonLabel>

                <FieldWidget {...f} />
              </IonItem>
            ))}
          </IonList>
        </IonContent>

        {children}
      </IonPage>
    </LegacyModal>
  );
}
