import {
  ignoreElements,
  tap,
  filter,
  mergeMap,
  catchError,
} from 'rxjs/operators';
import { EMPTY, merge, of, concat } from 'rxjs';
import { map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router';

import { DefaultEpic } from '@vitrona/state';
import {
  fetchProduct,
  fetchProductAsync,
  fetchProductKinds,
  fetchProductKindsAsync,
  fetchProducts,
  fetchProductsAsync,
} from './actions';
import {
  GetProductKindsRequest,
  GetProductRequest,
  GetProductsRequest,
} from '@vitrona/api/catalogue';
import { IMatch, IRoute } from './types';
import { ofAction } from './ofAction';

const routes: { id: string; path: string; exact?: boolean }[] = [
  // {
  //   id: 'catalogue',
  //   path: '/catalogue',
  //   exact: true,
  // },
  {
    id: 'productList',
    path: '/catalogus/:id',
    exact: true,
  },
];

export const routeChangeEpic: DefaultEpic = (actions$) =>
  actions$.pipe(
    // prettier-ignore
    ofType(LOCATION_CHANGE),
    mergeMap(({ payload }) => {
      const { location } = payload;
      const result: { route: IRoute; match: IMatch } = {
        route: null,
        match: null,
      };

      for (const route of routes) {
        const match = matchPath(location.pathname, route);

        if (match !== null) {
          result.route = route;
          result.match = match;

          break;
        }
      }

      if (result.match !== null) {
        return [fetchProducts(result)];
      }

      return EMPTY;
    })
  );

export const queryCatalogueEpic: DefaultEpic = (actions$) =>
  actions$.pipe(
    ofType(fetchProducts.type),
    mergeMap(({ payload }) => {
      const { route, match } = payload;

      switch (route.id) {
        case 'productList':
          return concat(
            of(fetchProductsAsync.started(payload)),
            GetProductsRequest.request(match.params.id).pipe(
              map((result) =>
                fetchProductsAsync.done({
                  params: payload,
                  result: result,
                })
              ),
              catchError((err) =>
                of(
                  fetchProductsAsync.failed({
                    params: payload,
                    error: err,
                  })
                )
              )
            )
          );

        default:
          return EMPTY;
      }
    })
  );

export const productKindsEpic: DefaultEpic = (actions$) =>
  actions$.pipe(
    ofType(fetchProductKinds.type),
    mergeMap(({ payload }) =>
      concat(
        of(fetchProductKindsAsync.started(payload)),
        GetProductKindsRequest.request().pipe(
          map((result) =>
            fetchProductKindsAsync.done({
              params: payload,
              result: result,
            })
          ),
          catchError((err) =>
            of(
              fetchProductKindsAsync.failed({
                params: payload,
                error: err,
              })
            )
          )
        )
      )
    )
  );

export const fetchProductEpic: DefaultEpic = (actions$) =>
  actions$.pipe(
    ofAction(fetchProduct),
    mergeMap(({ payload }) =>
      concat(
        of(fetchProductAsync.started(payload)),
        GetProductRequest.request(payload).pipe(
          map((result) =>
            fetchProductAsync.done({
              params: payload,
              result: result,
            })
          ),
          catchError((err) =>
            of(
              fetchProductAsync.failed({
                params: payload,
                error: err,
              })
            )
          )
        )
      )
    )
  );
