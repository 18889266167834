import { Record } from 'immutable';
import {
  IAPIOfferLine,
  IAPIOfferLineDetail,
  IAPISubOfferLine,
  IOfferLine,
  IOfferLineOption,
} from './types';

const defaultProps: IOfferLine = {
  id: '',
  offerId: '',
  productId: '',
  quantity: 1,
  productVariantId: '',
  type: 'OfferLine',
  title: '',
  options: [],
};

const defaultOptionProps: IOfferLineOption = {
  id: '',
  type: 'OfferLineOption',
  properties: {
    id: '',
    quantity: 0,
    value: null,
    productOption: '',
    productOptionValueType: '',
    productOptionToggleValue: void 0,
    productOptionItemValue: '',
    dimension1: null,
    dimension2: null,
    dimension3: null,
  },
};

export class Option extends Record<IOfferLineOption>(defaultOptionProps) {
  public static fromAPI(props: IAPISubOfferLine) {
    return new Option({
      id: props.id,
      properties: {
        ...props,
        quantity: props.amountOfItems,
        value:
          props.productOptionValueType === 'productoptiontoggle'
            ? props['productOptionToggleValue']
            : props['productOptionItemValue'],
      },
    });
  }
}

export class OfferLine extends Record<IOfferLine>(defaultProps) {
  public static fromAPI(props: IAPIOfferLine) {
    return new OfferLine({
      id: props.id,
      offerId: props.offer,
      productId: props.product,
      productVariantId: props.productVariant,
      quantity: props.amountOfItems,
      title: '',
    });
  }

  public static fromDetailAPI(props: IAPIOfferLineDetail) {
    const options = props.subOfferLines.map(({ id }) => id);

    return new OfferLine({
      id: props.id,
      offerId: props.offer,
      productId: props.product,
      productVariantId: props.productVariant,
      quantity: props.amountOfItems,
      title: '',
      options,
    });
  }

  public static empty() {
    return new OfferLine();
  }
}
