import { Map } from 'immutable';
import { applyMiddleware, compose, createStore } from 'redux';
import {
  routerMiddleware,
  connectRouter,
} from 'connected-react-router/immutable';

import setupEpics from './setupEpics';
import createReducer from './setupReducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
const initialState = Map<{}, {}>();

export function configureStore(
  preloadedState: Map<{}, {}> = initialState,
  history
) {
  const routerReducer = connectRouter(history);
  const { epicMiddleware, runEpics } = setupEpics();

  const middlewares = [
    epicMiddleware,
    // Syncs the location/URL path to the state
    routerMiddleware(history),
  ];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    createReducer({
      router: routerReducer,
    }),
    /* eslint-disable-next-line */
    // @ts-ignore
    preloadedState,
    composeEnhancers(...enhancers)
  );

  runEpics();

  if (module.hot) {
    module.hot.accept('./setupReducers', () => {
      store.replaceReducer(
        createReducer({
          router: routerReducer,
        })
      );
    });
  }

  return store;
}
