import { JSONSchema7 } from 'json-schema';
import { createSelector } from 'reselect';

import { ChoiceOption, Product, ToggleOption } from '@vitrona/api/catalogue';
import { OfferLine } from '@vitrona/api/offer';

function getCatalogueItems(state) {
  return state.getIn(['catalogue', 'items']);
}

function getOfferItems(state) {
  return state.getIn(['offers', 'items']);
}

function getActiveLine(state) {
  return state.getIn(['offers', 'activeLine', 'id']);
}

export const selectActiveLine = createSelector(
  getActiveLine,
  getOfferItems,
  (activeLineId, offerItems): OfferLine =>
    offerItems.get(activeLineId, OfferLine.empty())
);

const selectProduct = createSelector(
  getCatalogueItems,
  selectActiveLine,
  (catalogue, line): Product => {
    return catalogue.get(line.get('productId'), Product.empty());
  }
);

const selectOptions = createSelector(
  getCatalogueItems,
  selectProduct,
  (catalogue, product) =>
    product.properties.options
      .map((optionId) => catalogue.get(optionId))
      .filter(Boolean)
);

const selectVariants = createSelector(
  getCatalogueItems,
  selectProduct,
  (catalogue, product) =>
    product.properties.variants
      .map((variantId) => catalogue.get(variantId))
      .filter(Boolean)
);

const selectBooleanOptions = createSelector(
  selectOptions,
  (options = []): ToggleOption[] =>
    options.filter((option) => option.type === 'toggle')
);

const selectBooleanOptionDefinitions = createSelector(
  selectBooleanOptions,
  (options) =>
    options.map(({ id, properties }) => ({
      label: properties.title,
      id,
      contentType: 'boolean',
      isRequired: properties.isRequired,
      default: properties.initialValue,
    }))
);

const selectBooleanOptionSchemas = createSelector(
  selectBooleanOptionDefinitions,
  (definitions) => {
    const properties = definitions.reduce((acc, def) => {
      const optionSchema = {
        title: def.label,
        type: def.contentType,
        default: def.default,
      } as JSONSchema7;

      acc[def.id] = optionSchema;

      return acc;
    }, {});

    return properties;
  }
);

function selectChoiceItemsFactory(id: string) {
  const selectItemIds = createSelector(
    getCatalogueItems,
    (catalogue) =>
      catalogue.get(id, { properties: { items: [] } }).properties.items
  );

  return createSelector(
    selectItemIds,
    getCatalogueItems,
    // prettier-ignore
    (ids, catalogue) => ids
      .map((id) => catalogue.get(id))
      .filter(Boolean)
  );
}

const selectChoiceOptions = createSelector(
  selectOptions,
  (options = []): ChoiceOption[] =>
    options.filter((option) => option.type === 'choice')
);

const selectProductSchema = createSelector(selectProduct, (product) => ({
  quantity: {
    title: 'Aantal',
    type: 'number',
    default: 1,
    minimum: 1,
  } as JSONSchema7,
}));

const selectChoiceOptionDefinitions = createSelector(
  selectChoiceOptions,
  (options) =>
    options.map(({ id, properties }) => ({
      label: properties.title,
      id,
      contentType: 'string',
      isRequired: properties.isRequired,
      // select doesn't like null
      default:
        properties.initialValue === null ? void 0 : properties.initialValue,
      uiSchema: {
        'ui:placeholder': `--Kies ${properties.title.toLowerCase()}--`,
        'ui:widget': 'select',
      },
    }))
);

const selectVariantDefinition = createSelector(selectProduct, (product) => ({
  label: 'Afmetingen',
  id: 'productVariant',
  contentType: 'string',
  isRequired: true,
  // select doesn't like null
  default: void 0,
  uiSchema: {
    'ui:placeholder': `--Kies afmeting--`,
    'ui:widget': 'select',
    'ui:help': product.properties.dimensions
      .map(({ label }) => label)
      .join(' x '),
  },
}));

function formatDimensionLabel(dimensions: { label: string; value: string }[]) {
  const values = `${dimensions.map((d) => d.value).join(' x  ')}`;
  return `${values}`;
}

const selectVariantItemSchemas = createSelector(
  selectVariantDefinition,
  selectVariants,
  (definition, options) => {
    const definitions = [definition];

    const properties = definitions.reduce((acc, def) => {
      const optionSchema = {
        title: def.label,
        type: def.contentType,
        default: def.default,

        anyOf: options.map((option) => ({
          type: 'string',
          enum: [option.id],
          title: formatDimensionLabel(option.properties.dimensions),
        })),
      } as JSONSchema7;

      acc[def.id] = optionSchema;

      return acc;
    }, {});

    return properties;
  }
);

const selectChoiceItems = createSelector(selectChoiceOptions, (options = []) =>
  options.reduce((acc, option) => {
    acc[option.id] = selectChoiceItemsFactory(option.id);

    return acc;
  }, {})
);

const selectChoiceOptionSchemas = createSelector(
  selectChoiceOptionDefinitions,
  (state) => state,
  selectChoiceItems,
  (definitions, state, itemSelectors) => {
    const properties = definitions.reduce((acc, def) => {
      const selector = itemSelectors[def.id] ? itemSelectors[def.id] : () => [];

      const optionSchema = {
        title: def.label,
        type: def.contentType,
        default: def.default,

        anyOf: selector(state).map((option) => ({
          type: 'string',
          enum: [option.id],
          title: option.properties.title,
        })),
      } as JSONSchema7;

      acc[def.id] = optionSchema;

      return acc;
    }, {});

    return properties;
  }
);

const selectProductOptionDefinitions = createSelector(
  selectBooleanOptionDefinitions,
  selectChoiceOptionDefinitions,
  (...definitions) => {
    return [
      {
        id: 'options',
        uiSchema: definitions
          .flatMap((d) => d)
          .reduce((acc, d) => {
            acc[d.id] = d.uiSchema;
            return acc;
          }, {}),
      },
    ];
  }
);

const selectLineOptions = createSelector(
  selectActiveLine,
  getOfferItems,
  (line, items) => line.options.map((id) => items.get(id)).filter(Boolean)
);

export const selectFormData = createSelector(
  selectActiveLine,
  selectLineOptions,
  (line, options) => {
    const optionsData = options.reduce((acc, option) => {
      acc[option.properties.productOption] = option.properties.value;
      return acc;
    }, {});

    return {
      quantity: line.quantity,
      productVariant:
        line.productVariantId !== null ? line.productVariantId : void 0,
      options: optionsData,
    };
  }
);

const selectRequiredOptions = createSelector(
  selectVariantDefinition,
  (...definitions) =>
    definitions
      .flatMap((list) => list)
      .filter((def) => def.isRequired)
      .map((def) => def.id)
);

const selectAllOptionsSchema = createSelector(
  selectBooleanOptionSchemas,
  selectChoiceOptionSchemas,

  selectBooleanOptionDefinitions,
  selectChoiceOptionDefinitions,

  (toggles, selects, ...optionDefinitions) => {
    const required = optionDefinitions
      .flatMap((list) => list)
      .filter((def) => def.isRequired)
      .map((def) => def.id);

    const schema = {
      // aka sub offer lines
      options: {
        type: 'object',
        properties: {
          ...selects,
          ...toggles,
        },
        required,
      } as JSONSchema7,
    };

    return schema;
  }
);

export const selectSchemaComposed = createSelector(
  selectProductSchema,
  selectVariantItemSchemas,
  selectAllOptionsSchema,
  selectRequiredOptions,
  (p1, variants, options, required) => {
    const schema: JSONSchema7 = {
      type: 'object',
      properties: {
        ...p1,
        ...variants,
        ...options,
      },
      required,
    };

    return schema;
  }
);

export const selectUiSchema = createSelector(
  selectProductOptionDefinitions,
  selectVariantDefinition,
  (definitions, variant) => {
    return definitions.concat(variant).reduce((acc, { id, uiSchema }) => {
      acc[id] = uiSchema;

      return acc;
    }, {});
  }
);
