import { Record } from 'immutable';
import { IAPIOffer, IOfferLine, IOfferProps, OfferUpdateProps } from './types';

const defaultOfferProps: IOfferProps = {
  id: '',
  status: 'draft',
  lines: [],
  email: '',
  type: 'Offer',
};

export class Offer extends Record<IOfferProps>(defaultOfferProps) {
  public static fromAPI(props: IAPIOffer) {
    return Offer.of({
      ...props,
      lines: props.offerLines.map(({ id }) => id),
      type: 'Offer',
    });
  }

  public static of(props: IOfferProps) {
    return new Offer(props);
  }

  public static updates(props: OfferUpdateProps) {
    return new Offer(props);
  }
}
