import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import {
  selectProductKinds,
  productKindSelectorFactory,
  productsByKindSelectorFactory,
} from './selectors';

// @TODO look at rtk-query how to optimize selector hooks
export function useSelectProductKinds() {
  return useSelector(selectProductKinds);
}

export function useSelectProductKind({ id }) {
  const selector = useMemo(() => productKindSelectorFactory(id), [id]);

  return useSelector(selector);
}

export function useSelectProductsByKind({ id }) {
  const selector = useMemo(() => productsByKindSelectorFactory(id), [id]);

  return useSelector(selector);
}
