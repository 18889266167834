import { combineReducers } from 'redux-immutable';

import * as catalogue from '@vitrona/state/catalogue';
import * as offer from '@vitrona/state/offer';

export default function createReducer(additionalReducers?: object) {
  return combineReducers({
    ...additionalReducers,
    [catalogue.reducerPath]: catalogue.reducer,
    [offer.reducerPath]: offer.reducer,
    // ...reducers,
  });
}
