import {
  IonContent,
  IonList,
  IonButtons,
  IonButton,
  IonIcon,
  IonFooter,
  IonToolbar,
} from '@ionic/react';
import { useActor } from '@xstate/react';
import React, { useEffect } from 'react';
import {
  readerOutline as reportIcon,
  pencil as editIcon,
  trash as trashIcon,
} from 'ionicons/icons';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { OrderedSet } from 'immutable';

import { ReportEditor } from '@vitrona/ui';
import { LineDetails, LineItem } from './LineItem';
import { selectActiveLine } from '@vitrona/state/offer';

function offerLinesSelectorFactory({ offerId }: { offerId: string }) {
  const getOfferLineIds = (state) =>
    state.getIn(['offers', 'tree', offerId], OrderedSet());

  const getCatalogueItems = (state) => state.getIn(['catalogue', 'items']);

  const selectOfferLines = createSelector(
    getOfferLineIds,
    (state) => state.getIn(['offers', 'items']),
    (ids, items) =>
      ids
        // prettier-ignore
        .filter((id) => items.has(id))
        .map((id) => items.get(id))
  );

  const selectOfferLinesFull = createSelector(
    selectOfferLines,
    getCatalogueItems,
    (lines, catalogue) =>
      lines.map((line) => {
        const product = catalogue.get(line.productId);

        return line.set('title', product.properties.title);
      })
  );

  return selectOfferLinesFull;
}

function useSelectOfferLines({ offerId }: { offerId: string }) {
  const selector = React.useMemo(() => offerLinesSelectorFactory({ offerId }), [
    offerId,
  ]);

  return useSelector(selector);
}

export function OfferEditor({
  offerRef,
  isOpen,
  offerId,
}: {
  // @TODO fix types for offerRef
  offerRef: any;
  isOpen: boolean;
  offerId: string;
}) {
  const [state, send] = useActor(offerRef);
  const offerLines = useSelectOfferLines({ offerId });

  const activeLineState = useSelector((state) =>
    state.getIn(['offers', 'activeLine'])
  );

  const activeLine = useSelector(selectActiveLine);

  const lineForm = React.useRef({
    submit: () => void 0,
    state: {},
  });

  function submit() {
    lineForm.current.submit();
  }

  function onLineSubmit(value) {
    send({ type: 'LINE.SUBMIT_SUCCESS', value });
  }

  function onLineFormError(errors) {
    send({ type: 'LINE.SUBMIT_ERROR', errors });
  }

  function toggleLineEdit({
    lineId,
    productId,
  }: {
    lineId: string;
    productId: string;
  }) {
    send({
      type: 'LINE.TOGGLE_EDIT',
      id: lineId,
      productId: productId,
    });
  }

  useEffect(
    () => {
      if (activeLine.id && activeLineState.status === 'active') {
        toggleLineEdit({
          lineId: activeLine.id,
          productId: activeLine.productId,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeLine, activeLineState.status]
  );

  return (
    <ReportEditor isOpen={isOpen} onDidDismiss={() => send('OFFER.CLOSE')}>
      <IonContent>
        <IonList>
          {offerLines.map((line) => (
            <LineItem
              key={line.id}
              title={line.title}
              highlight={activeLineState.id === line.id}
              renderActions={
                <IonButtons slot="end">
                  <IonButton
                    onClick={() => send({ type: 'LINE.REMOVE', id: line.id })}
                    color="danger"
                    fill="outline"
                    slot="end"
                  >
                    <IonIcon icon={trashIcon} />
                  </IonButton>

                  {state.matches('editingLine') &&
                    activeLineState.id === line.id && (
                      <IonButton
                        onClick={() => send('LINE.CANCEL_EDIT')}
                        color="secondary"
                        fill="outline"
                        slot="end"
                      >
                        Annuleren
                      </IonButton>
                    )}

                  {state.matches('editingLine') &&
                    activeLineState.id === line.id && (
                      <IonButton
                        onClick={() => submit()}
                        color="secondary"
                        fill="outline"
                        slot="end"
                      >
                        Toepassen
                      </IonButton>
                    )}

                  {(state.matches('idle') ||
                    activeLineState.id !== line.id) && (
                    <IonButton
                      onClick={() =>
                        toggleLineEdit({
                          lineId: line.id,
                          productId: line.productId,
                        })
                      }
                      color="secondary"
                      fill="outline"
                      slot="end"
                    >
                      <IonIcon slot="end" icon={editIcon} />
                      Open
                    </IonButton>
                  )}
                </IonButtons>
              }
            >
              {state.matches('editingLine') &&
                activeLineState.id === line.id && (
                  <LineDetails
                    ref={lineForm}
                    onSubmit={onLineSubmit}
                    onError={onLineFormError}
                  />
                )}
            </LineItem>
          ))}
        </IonList>
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonButtons slot="secondary">
            <IonButton onClick={() => send('WIZARD.START')} fill="outline">
              Offerte afronden
            </IonButton>
            <IonButton onClick={() => send('OFFER.CLOSE')} fill="solid">
              Verder met samenstellen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </ReportEditor>
  );
}
