import {
  IonContent,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { fileTrayFullOutline } from 'ionicons/icons';
import './Menu.css';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProductKinds,
  useSelectProductKinds,
} from '@vitrona/state/catalogue';
import clsx from 'clsx';

import logoUrl from './logo-vitrona-horizontaal.png';

const LogoWrap = styled.div`
  margin-top: calc(var(--spacing) * -1);

  a {
    display: inline-block;
  }
`;

function Logo() {
  return (
    <LogoWrap>
      <a href="/">
        <img src={logoUrl} alt="Logo Vitrona" />
      </a>
    </LogoWrap>
  );
}

interface AppPage {
  url: string;
  icon?: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Offertes',
    url: '/page/offertes',
    icon: fileTrayFullOutline,
  },
];

export const Menu: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const productKinds = useSelectProductKinds();

  useEffect(() => {
    dispatch(fetchProductKinds());
  }, [dispatch]);

  // @TODO research using react portal to inject menus in here for anywhere
  // in the tree

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <Logo />

        <IonList>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? 'selected' : ''
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" icon={appPage.icon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="catalogue-productkinds" className="pt-0">
          <IonItemDivider style={{ paddingLeft: 10 }}>
            <IonLabel>Catalogus</IonLabel>
          </IonItemDivider>

          {productKinds.map((page) => {
            return (
              <IonMenuToggle key={page.id} autoHide={false}>
                <IonItem
                  className={location.pathname === page.url ? 'selected' : ''}
                  routerLink={page.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonLabel>{page.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};
