import React, { useMemo } from 'react';
import { Modal } from '@vitrona/ui';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import Form, {
  withTheme,
  UiSchema,
  WidgetProps,
  Widget,
  FieldProps,
  FieldTemplateProps,
  utils,
  ObjectFieldTemplateProps,
  FormProps,
  ISubmitEvent,
} from '@rjsf/core';
import styled from '@emotion/styled';
import { JSONSchema7 } from 'json-schema';

const { ADDITIONAL_PROPERTY_FLAG } = utils;

interface InputProps extends WidgetProps {
  value: string | null;
  type: string;
}

function TextWidget({
  label,
  value,
  options,
  onChange,
  onBlur,
  onFocus,
  ...rest
}: InputProps) {
  const _onChange = ({ target: { value } }) => {
    return onChange(value === '' ? options.emptyValue : value);
  };

  const inputProps = {
    type: 'text' as const,
    value: value == null ? '' : value,
    onChange: _onChange,
    onIonChange: _onChange,
  };

  // @TODO Fix types
  return (
    <IonInput
      {...inputProps}
      {...rest}
      onBlur={onBlur && ((event) => onBlur(rest.id, event.target.value))}
      onFocus={onFocus && ((event) => onFocus(rest.id, event.target.value))}
    />
  );
}

function EmailWidget(props: InputProps) {
  return <TextWidget type="email" {...props} />;
}

function FieldTemplate({
  id,
  label,
  displayLabel,
  rawDescription,
  help,
  description,
  errors,
  children,
  schema,
  uiSchema,
  rawErrors = [],
  ...rest
}: FieldTemplateProps) {
  // @TODO render by schemaType
  const schemaType = utils.getSchemaType(schema);

  if (schemaType === 'object' && id !== 'root') {
    return children;
  }

  if (id !== 'root') {
    /* Keeping it for now because this form requires more work */
    // console.log(
    //   'field props: %o, %o, %o',
    //   schemaType,
    //   id,
    //   label,
    //   rawDescription,
    //   rest
    //   // rawDescription, help, rest,
    // );

    const sublabels = [];

    if (rawErrors.length) {
      for (const error of rawErrors) {
        sublabels.push(
          <SubLabel color="danger" key={sublabels.length + 1}>
            {error}
          </SubLabel>
        );
      }
    }

    if (rawDescription) {
      sublabels.push(
        <SubLabel key={sublabels.length + 1}>{rawDescription}</SubLabel>
      );
    }

    return (
      <IonItem key={id}>
        <IonLabel position="stacked">
          <IonText>{label}</IonText>
        </IonLabel>

        {children}

        {sublabels.length > 0 && <Stack>{sublabels}</Stack>}
      </IonItem>
    );
  }

  return <IonList>{children}</IonList>;
}

// Will be rendered inside IonItemGroup
function ObjectFieldTemplate({
  title,
  properties,
  description,
  ...rest
}: ObjectFieldTemplateProps) {
  return (
    <IonItemGroup>{properties.map((element) => element.content)}</IonItemGroup>
  );
}

// NoOp for now
// Update: ErrorList can also be disabled via settings
function ErrorList() {
  return null;
}

const IonForm = withTheme({
  widgets: {
    TextWidget,
    EmailWidget,
  },
  FieldTemplate,
  ObjectFieldTemplate,
  ErrorList,
});

const schema: JSONSchema7 = {
  type: 'object',

  properties: {
    email: {
      title: 'E-mail',
      type: 'string',
      format: 'email',
    },
    firstName: {
      title: 'Voornaam',
      type: 'string',
    },
    lastNamePrefix: {
      title: 'Tussenvoegsel',
      type: 'string',
    },
    lastName: {
      title: 'Achternaam',
      type: 'string',
    },
  },

  required: ['email'],
};

const uiSchema: UiSchema = {
  email: {
    'ui:placeholder': 'Voer e-mailadres in van de klant',
  },
  organization: {
    'ui:placeholder': 'Naam organisatie',
  },
};

// children renders an empty span by default because we need the submit button
// outside form because wrapping ionic components inside a form gives trouble
const BasicClientDetailsForm = React.forwardRef(function BasicClientDetailsForm(
  {
    children = <span />,
    onSubmit,
    formData,
  }: {
    children?: React.ReactNode;
    formData?: unknown;
    onSubmit: (formData: unknown) => void;
  },
  ref
) {
  return (
    <IonForm
      ref={ref}
      noHtml5Validate
      schema={schema}
      uiSchema={uiSchema}
      onSubmit={onSubmit}
      formData={formData}
    >
      {children}
    </IonForm>
  );
});

const Stack = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: var(--spacing-1);
  }
`;

const SubLabel = styled(IonText)`
  font-size: var(--sublabel-font-size, inherit);
`;

SubLabel.defaultProps = { color: 'medium' };

export function BasicClientDetails({
  isOpen = false,
  onDidDismiss,
  children = null,
  onAbort = () => void 0,
  onSubmit = () => void 0,
  defaultValues = {},
}: {
  isOpen?: boolean;
  onDidDismiss?: () => void;
  children?: React.ReactNode;
  onAbort: () => void;
  onSubmit: (formData: unknown) => void;
  defaultValues?: unknown;
}) {
  const formRef = React.useRef({
    submit: () => void 0,
    state: {},
  });

  function submit() {
    formRef.current.submit();
  }

  function handleSubmit(event: ISubmitEvent<unknown>) {
    onSubmit(event.formData);
  }

  return (
    <Modal isOpen={isOpen} onDidDismiss={onDidDismiss} backdropDismiss={false}>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Nieuwe offerte - basisgegevens</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <BasicClientDetailsForm
            formData={defaultValues}
            ref={formRef}
            onSubmit={handleSubmit}
          />
        </IonContent>

        <IonFooter>
          <IonToolbar>
            <IonButtons slot="secondary">
              <IonButton onClick={onAbort} fill="outline" color="danger">
                Annuleren
              </IonButton>
              <IonButton onClick={submit} fill="solid">
                Opslaan &amp; sluiten
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </Modal>
  );
}
