// @TODO use actual products from catalogue
export const products = [
  {
    type: {
      title: 'Fokker 1600',
    },
    description: '',
    serie: {
      title: 'Semi Casette',
    },
    productKind: {
      title: 'Knikarmscherm',
      slug: 'knikarmscherm',
      key: 'KA',
    },
    brand: {
      title: 'Vitrona',
    },
  },
  {
    type: {
      title: 'Fokker 2600',
    },
    description: '',
    serie: {
      title: 'Furore Cassette',
    },
    productKind: {
      title: 'Knikarmscherm',
      slug: 'knikarmscherm',
      key: 'KA',
    },
    brand: {
      title: 'Vitrona',
    },
  },
  {
    type: {
      title: 'Spectra Smart Choice',
    },
    description: '',
    serie: {
      title: 'Premium Collection',
    },
    productKind: {
      title: 'Knikarmscherm',
      slug: 'knikarmscherm',
      key: 'KA',
    },
    brand: {
      title: 'Luxaflex',
    },
  },
].map((p) => {
  const { brand, type, serie, productKind } = p;

  const title = `${brand.title} ${serie.title} ${type.title}`;
  const id = title.replace(/ /gim, '-').toLowerCase();
  const url = `/catalogue/${productKind.slug}/${id}`;

  return {
    ...p,
    title,
    id,
    url,
  };
});

export function getProducts() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(products);
    }, 300);
  });
}

export function createReport() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        id: Date.now(),
        type: 'report', // report | offer
        status: 'draft',
        createdAt: new Date().toISOString(),
      });
    }, 300);
  });
}
