import { Settings } from '@vitrona/settings';

// tslint:disable no-invalid-template-strings

export default (src: Settings) => {
  return (
    src
      // APP
      // URL is not determined yet
      .define('APP_URL', 'https://vitrona.fluxility.dev/')

      // API
      .define('API_ROOT', process.env.NX_API_ROOT)
      .define('API_TOKEN', process.env.NX_API_AUTH_TOKEN)
      .define('API_VERSION', 'v1')
      .define('API_PATH', '/api/')
      .define(
        'API_URL',
        ['API_ROOT', 'API_VERSION', 'API_PATH'],
        '${API_ROOT}${API_PATH}${API_VERSION}'
      )
  );
};
