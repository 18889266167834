import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonLabel,
  IonButton,
  IonRouterLink,
  IonInput,
  IonButtons,
  IonContent,
  IonFooter,
  IonToolbar,
  IonItemGroup,
  IonItemDivider,
  IonSelect,
  IonSelectOption,
  IonListHeader,
  IonLoading,
  IonToast,
} from '@ionic/react';
import {
  readerOutline as reportIcon,
  pencil as editIcon,
} from 'ionicons/icons';
import styled from '@emotion/styled';
import { useMachine } from '@xstate/react';

import { Page, ReportEditor, ReportOverview } from '@vitrona/ui';
import { machine } from './machine';
// Move to @vitrona/ui
import { ClientDetails, clientData } from './ClientDetails';
import { ReportSummary } from './ReportSummary';
import { ProductCard } from '../ProductCard';

const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  position: relative;
`;

const BlockItem = styled.div`
  max-width: ${1072 + 32}px;
  width: 100%;
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
  hyphens: auto;
`;

type onAdd = ({ id: string }) => void;

const Input = styled(IonInput)`
  border: 1px solid var(--ion-border-color, #ddd);
`;

function SearchField() {
  return <Input type="search" placeholder="Zoeken"></Input>;
}

function selectLines({ result, items }) {
  return Array.from(result.values()).map((id) => items[id]);
}

const LineLabel = styled(IonLabel)`
  font-weight: 600;
  /* @TODO use brand colors */
  color: ${({ color }) => (color ? 'inherit' : '#444 !important')};
`;

function LineDetails() {
  return (
    <>
      <IonItem lines="inset">
        <LineLabel>Aantal</LineLabel>
        <IonInput
          placeholder="voer aantal in"
          type="number"
          value={2}
        ></IonInput>
      </IonItem>

      <IonItem lines="inset">
        <LineLabel>Breedte</LineLabel>
        <IonInput
          placeholder="voer breedte in"
          type="number"
          value={200}
        ></IonInput>
      </IonItem>

      <IonItem lines="inset">
        <LineLabel>Hoogte</LineLabel>
        <IonInput
          placeholder="voer hoogte in"
          type="number"
          value={100}
        ></IonInput>
      </IonItem>

      <IonItem lines="inset">
        <LineLabel>Doekkleur</LineLabel>
        <IonSelect
          value={'zwart-9005'}
          placeholder="Selecteer kleur"
          onIonChange={console.log}
        >
          <IonSelectOption value="zwart-9005">Zwart 9005</IonSelectOption>
          <IonSelectOption value="creme-wit-9001">Wit 9001</IonSelectOption>
        </IonSelect>
      </IonItem>

      <IonItem lines="inset">
        <LineLabel>Framekleur</LineLabel>
        <IonSelect
          value={'zwart-9005'}
          placeholder="Selecteer kleur"
          onIonChange={console.log}
        >
          <IonSelectOption value="zwart-9005">Zwart 9005</IonSelectOption>
          <IonSelectOption value="creme-wit-9001">Wit 9001</IonSelectOption>
        </IonSelect>
      </IonItem>

      <IonItem lines="inset">
        <LineLabel>Montage</LineLabel>
        <IonSelect placeholder="Selecteer montage" onIonChange={console.log}>
          <IonSelectOption value="m1">Montage optie 1</IonSelectOption>
          <IonSelectOption value="m2">Montage optie 2</IonSelectOption>
        </IonSelect>
      </IonItem>
    </>
  );
}

function LineItem({
  id,
  title,
  highlight = false,
  properties,
  renderActions = null,
  children,
}) {
  return (
    <IonItemGroup
      className={`${highlight ? 'border-y-1 border-primary-100' : ''}`}
    >
      <IonItem lines="full">
        <IonLabel>
          <IonText style={{ fontWeight: 'bold' }}>{title}</IonText>

          <IonText color="dark">
            <p>
              <span>Prijs:</span>&nbsp;
              <span>&euro;</span>&nbsp;
              <span>999</span>
            </p>
          </IonText>
        </IonLabel>

        {renderActions}
      </IonItem>

      {children}
    </IonItemGroup>
  );
}

// @TODO rename. This is a list page that lists products of specific top level
// category
export function CatalogueCategory() {
  const { categorySlug } = useParams<{ categorySlug: string }>();
  const [state, send] = useMachine(machine, { devTools: false });
  const {
    result,
    items = {},
    report,
    activeLine,
    isReportOpen,
  } = state.context;
  const products = Array.from(result.values()).map((id) => items[id]);
  const reportLines = selectLines(report);

  // @TODO remove this, because it's iOS specific
  const startModelRef = React.useRef(null);

  // Simulate stepping through the a wizard with a single product added to the
  // report (DEBUG only)
  useEffect(() => {
    return;
    setTimeout(() => {
      send('REPORT.OPEN');
    }, 300);

    setTimeout(() => {
      const { value } = result.keys().next();

      send('REPORT.ADD_LINE', {
        id: value,
        contentType: 'product',
      });

      send('LINE.TOGGLE_EDIT', { id: value });
    }, 1000);

    setTimeout(() => {
      // send(['REPORT_OVERVIEW.OPEN', 'WIZARD.NEXT', 'WIZARD.NEXT']);
    }, 1200);
  }, []);

  return (
    <Page
      title={categorySlug}
      renderHeaderToolbarSecondary={() => (
        <IonButtons slot="secondary">
          <IonButton
            fill="solid"
            onClick={() => {
              send('REPORT.OPEN');
            }}
          >
            <IonIcon slot="end" icon={reportIcon} />
            Offerte
          </IonButton>
        </IonButtons>
      )}
    >
      <IonLoading
        isOpen={state.matches('sendingReport.started')}
        message={'Offerte versturen'}
        duration={5000}
        onDidDismiss={() => send('REPORT.SEND_DISMISSED')}
      />

      <IonToast
        isOpen={state.matches('sendingReport.send')}
        message="Offerte is verstuurd."
        duration={2000}
      />

      <ReportEditor
        modalRef={startModelRef}
        isOpen={isReportOpen}
        onDidDismiss={() => send('REPORT.CLOSE')}
      >
        <IonContent>
          <IonList>
            {reportLines.map((line, i) => (
              <LineItem
                key={line.id}
                {...line}
                highlight={
                  state.matches('editingLine') && activeLine === line.id
                }
                renderActions={
                  <IonButtons slot="end">
                    {state.matches('editingLine') && activeLine === line.id && (
                      <IonButton
                        onClick={() => send('LINE.CANCEL_EDIT')}
                        color="secondary"
                        fill="outline"
                        slot="end"
                      >
                        Annuleren
                      </IonButton>
                    )}

                    <IonButton
                      onClick={() => send('LINE.TOGGLE_EDIT', { id: line.id })}
                      color="secondary"
                      fill="outline"
                      slot="end"
                    >
                      <IonIcon slot="end" icon={editIcon} />
                      {state.matches('editingLine') && activeLine === line.id
                        ? 'Toepassen'
                        : 'Bewerken'}
                    </IonButton>
                  </IonButtons>
                }
              >
                {state.matches('editingLine') && activeLine === line.id && (
                  <LineDetails />
                )}
              </LineItem>
            ))}
          </IonList>
        </IonContent>

        <IonFooter>
          <IonToolbar>
            <IonButtons slot="secondary">
              <IonButton onClick={() => send('WIZARD.START')} fill="outline">
                Offerte afronden
              </IonButton>
              <IonButton onClick={() => send('REPORT.CLOSE')} fill="solid">
                Verder met samenstellen
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </ReportEditor>

      <ReportOverview
        isOpen={state.matches('reportOverview')}
        onDidDismiss={() => send('WIZARD.PAUSE')}
      >
        <IonContent>
          <IonList>
            {reportLines.map((line) => (
              <IonItem key={line.id}>
                <IonLabel>
                  <IonText>{line.title}</IonText>-
                  {/* @TODO ise spacing util class  */}
                  <div style={{ marginTop: '.5rem' }}></div>
                  {line.properties.map((p, i) => (
                    <p key={`${p.contentType}-${i}`}>
                      {p.label}: {p.formattedValue}
                    </p>
                  ))}
                </IonLabel>

                <IonButton color="secondary" fill="outline" slot="end">
                  <IonIcon slot="end" icon={editIcon} />
                  Bewerken
                </IonButton>
              </IonItem>
            ))}
          </IonList>
        </IonContent>

        <IonFooter>
          <IonToolbar>
            <IonButtons slot="secondary">
              <IonButton onClick={() => send('WIZARD.PREVIOUS')} fill="outline">
                Vorige
              </IonButton>
              <IonButton onClick={() => send('WIZARD.NEXT')} fill="solid">
                Volgende
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </ReportOverview>

      <ClientDetails
        isOpen={state.matches('clientDetails')}
        onDidDismiss={() => send('WIZARD.PAUSE')}
      >
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="secondary">
              <IonButton onClick={() => send('WIZARD.PREVIOUS')} fill="outline">
                Vorige
              </IonButton>
              <IonButton onClick={() => send('WIZARD.NEXT')} fill="solid">
                Volgende
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </ClientDetails>

      <ReportSummary
        isOpen={state.matches('reportSummary')}
        onDidDismiss={() => send('REPORT_SUMMARY.CLOSE')}
      >
        {state.matches('reportSummary') && (
          <IonContent>
            <IonList>
              <IonListHeader lines="inset">
                <IonLabel>Producten</IonLabel>
              </IonListHeader>
              {reportLines.map((line) => (
                <IonItem lines="none" key={line.id}>
                  <IonLabel>
                    <IonText>{line.title}</IonText>

                    {/* @TODO ise spacing util class  */}
                    <div style={{ marginTop: '.5rem' }}></div>

                    {line.properties.map((p, i) => (
                      <p key={`${p.contentType}-${i}`}>
                        {p.label}: {p.formattedValue}
                      </p>
                    ))}
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>

            <IonList>
              <IonListHeader lines="inset">
                <IonLabel>Klantgegevens</IonLabel>
              </IonListHeader>
              <IonItem lines="none">
                <IonLabel>
                  {clientData.result
                    .filter((id) => !!clientData.items[id].value)
                    .map((id, i) => (
                      <p style={{ marginTop: '.5rem' }} key={`${id}-${i}`}>
                        <span style={{ display: 'block' }}>
                          <strong>{clientData.items[id].label}</strong>
                        </span>
                        <span>{clientData.items[id].formattedValue}</span>
                      </p>
                    ))}
                </IonLabel>
              </IonItem>
            </IonList>
          </IonContent>
        )}

        <IonFooter>
          <IonToolbar>
            <IonButtons slot="secondary">
              <IonButton onClick={() => send('WIZARD.PREVIOUS')} fill="outline">
                Vorige
              </IonButton>
              <IonButton onClick={() => send('WIZARD.END')} fill="solid">
                Versturen
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </ReportSummary>

      <Block className="ion-padding-top">
        <BlockItem>
          <IonGrid>
            <IonRow>
              <IonCol size="9">
                <SearchField />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="9">
                {products.map((p) => (
                  <ProductCard
                    key={p.id}
                    {...p}
                    renderActions={() => (
                      <IonCardContent>
                        <IonButton
                          onClick={() => {
                            send('REPORT.OPEN');

                            send('REPORT.ADD_LINE', {
                              id: p.id,
                              contentType: 'product',
                            });

                            send('LINE.TOGGLE_EDIT', { id: p.id });
                          }}
                          color="dark"
                          fill="outline"
                          size="small"
                        >
                          Toevoegen
                        </IonButton>
                        {/* @TODO use color that doesn't look disabled */}
                        <IonButton color="medium" fill="outline" size="small">
                          Details
                        </IonButton>
                      </IonCardContent>
                    )}
                  />
                ))}
              </IonCol>
              {/* <IonCol>
                <IonText>
                  <p>@TODO add filters</p>
                </IonText>
              </IonCol> */}
            </IonRow>
          </IonGrid>
        </BlockItem>
      </Block>
    </Page>
  );
}
