import styled from '@emotion/styled';
import {
  IonItemGroup,
  IonItem,
  IonLabel,
  IonText,
  IonInput,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import React from 'react';
import Form, { AjvError, IChangeEvent } from '@rjsf/core';
import { useSelector } from 'react-redux';
import {
  selectFormData,
  selectSchemaComposed,
  selectUiSchema,
} from '@vitrona/state/offer';

const LineLabel = styled(IonLabel)`
  font-weight: 600;
  /* @TODO use brand colors */
  color: ${({ color }) => (color ? 'inherit' : '#444 !important')};
`;

export const LineDetails = React.forwardRef(function LineDetails(
  {
    children = <span />,
    onSubmit,
    onError,
  }: {
    children?: React.ReactNode;
    onSubmit: (event: IChangeEvent) => void;
    onError: (errors: AjvError[]) => void;
  },
  ref
) {
  const formData = useSelector(selectFormData);
  const schema = useSelector(selectSchemaComposed);
  const uiSchema = useSelector(selectUiSchema);

  return (
    <Form
      ref={ref}
      formData={formData}
      schema={schema}
      uiSchema={uiSchema}
      onSubmit={onSubmit}
      onError={onError}
    >
      {children}
    </Form>
  );
});

/* @NOTE currently used as example form components */
function LineDetailsX() {
  return (
    <>
      <IonItem lines="inset">
        <LineLabel>Aantal</LineLabel>
        <IonInput
          placeholder="voer aantal in"
          type="number"
          value={2}
        ></IonInput>
      </IonItem>

      <IonItem lines="inset">
        <LineLabel>Breedte</LineLabel>
        <IonInput
          placeholder="voer breedte in"
          type="number"
          value={200}
        ></IonInput>
      </IonItem>

      <IonItem lines="inset">
        <LineLabel>Hoogte</LineLabel>
        <IonInput
          placeholder="voer hoogte in"
          type="number"
          value={100}
        ></IonInput>
      </IonItem>

      <IonItem lines="inset">
        <LineLabel>Doekkleur</LineLabel>
        <IonSelect
          value={'zwart-9005'}
          placeholder="Selecteer kleur"
          onIonChange={console.log}
        >
          <IonSelectOption value="zwart-9005">Zwart 9005</IonSelectOption>
          <IonSelectOption value="creme-wit-9001">Wit 9001</IonSelectOption>
        </IonSelect>
      </IonItem>

      <IonItem lines="inset">
        <LineLabel>Framekleur</LineLabel>
        <IonSelect
          value={'zwart-9005'}
          placeholder="Selecteer kleur"
          onIonChange={console.log}
        >
          <IonSelectOption value="zwart-9005">Zwart 9005</IonSelectOption>
          <IonSelectOption value="creme-wit-9001">Wit 9001</IonSelectOption>
        </IonSelect>
      </IonItem>

      <IonItem lines="inset">
        <LineLabel>Montage</LineLabel>
        <IonSelect placeholder="Selecteer montage" onIonChange={console.log}>
          <IonSelectOption value="m1">Montage optie 1</IonSelectOption>
          <IonSelectOption value="m2">Montage optie 2</IonSelectOption>
        </IonSelect>
      </IonItem>
    </>
  );
}

export function LineItem({
  title,
  highlight = false,
  renderActions = null,
  children,
}) {
  return (
    <IonItemGroup
      className={`${highlight ? 'border-y-1 border-primary-100' : ''}`}
    >
      <IonItem lines="full">
        <IonLabel>
          <IonText style={{ fontWeight: 'bold' }}>{title}</IonText>

          <IonText color="dark">
            <p>
              <span>Prijs:</span>&nbsp;
              <span>&euro;</span>&nbsp;
              <span>XXX</span>
            </p>
          </IonText>
        </IonLabel>

        {renderActions}
      </IonItem>

      {children}
    </IonItemGroup>
  );
}
