import { BaseGetRequest } from '@vitrona/base-request';
import { of } from 'rxjs';
import env from '@vitrona/env';
import { map, tap } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEmptyContext {}

export class GetProductKindsRequest extends BaseGetRequest<IEmptyContext> {
  public url = `${env.get<string>('API_URL')}/product-kinds`;

  public static request() {
    return new GetProductKindsRequest().makeRequest({});
  }
}

export class GetProductRequest extends BaseGetRequest<IEmptyContext> {
  public url = `${env.get<string>('API_URL')}/products`;
  method = 'GET';

  public static request({ id }) {
    return new GetProductRequest().makeRequest({ id });
  }

  public getUrl({ id }: { id: string }) {
    return `${this.url}/${id}/`;
  }
}

export class GetProductsRequest extends BaseGetRequest<{ id: string }> {
  public url = `${env.get<string>('API_URL')}/product-kinds`;

  public static request(id: string) {
    return (
      new GetProductsRequest()
        .makeRequest({ id })
        // @TODO remove temporary patch when REST API implemented getting products
        //       by category id/slug
        .pipe(
          map(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ({ id, products = [] } = { products: [] }) =>
              products.map((product) => ({
                ...product,
                productKind: id,
              }))
          )
        )
    );
  }

  public getUrl({ id }: { id: string }) {
    return `${this.url}/${id}/`;
  }
}
