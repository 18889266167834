import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import styled from '@emotion/styled';
import { inspect } from '@xstate/inspect';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import { Menu, Page } from '@vitrona/ui';
import { CatalogueCategory } from '@vitrona/ui/catalogue';
import { ProductListPage } from './pages/ProductListPage';
import createStore from './state';

/* Theme variables */
import './theme/variables.css';
import './css/utils.css';
import { HistorySnitch } from './components/HistorySnitch';
import { TestForm } from './components/Form';

if (process.env.NODE_ENV === 'development') {
  inspect({ iframe: false });
}

const history = createBrowserHistory();
const store = createStore(history);

const StyledSplitPlane = styled(IonSplitPane)`
  --side-max-width: 256px;
`;

const App: React.FC = () => {
  return (
    <IonApp>
      <Provider store={store}>
        <HistorySnitch history={history} />
        <IonReactRouter history={history}>
          <StyledSplitPlane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route path="/catalogus/:id" component={ProductListPage} exact />
              <Route
                path="/catalogus-prototype/:categorySlug"
                component={CatalogueCategory}
                exact
              />
              <Route
                path="/catalogus/"
                render={() => <Page title="Catalogus" />}
                exact
              />

              {process.env.NODE_ENV === 'development' ? (
                <Route path="/forms/" render={() => <TestForm />} exact />
              ) : (
                <Route />
              )}

              <Route
                path="/page/:name"
                render={() => <Page title="Offertes" />}
                exact
              />
              <Redirect from="/" to="/catalogus" exact />
            </IonRouterOutlet>
          </StyledSplitPlane>
        </IonReactRouter>
      </Provider>
    </IonApp>
  );
};

export default App;
