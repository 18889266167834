import { Observable, forkJoin, of, throwError } from 'rxjs';
import { mergeMap, concatMap, map } from 'rxjs/operators';

// Source: https://gist.github.com/meandmax/65b20f0dccf65f2854c3#file-getcookiebyname-js-L24
function getCookie(name: string) {
  const pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
  return pair ? pair[1] : null;
}

export abstract class BaseRequest<T> {
  public contentType = 'application/json';
  public abstract method: string;
  protected options = {
    // Temporarily enabled to make authorized calls to the server
    // There might be a chance this won't work when published on the server
    useCookieCsrf: true,
  };

  public fetchMethod(url: string, init?: RequestInit) {
    return fetch(url, {
      ...init,
      headers: {
        ...(init || {}).headers,
      },
    });
  }

  public expectedStatus = 200;

  public abstract url?: string;

  public getUrl(context: T) {
    const querystring = this.getQuerystring(context);
    return querystring ? `${this.url}?${querystring}` : this.url;
  }

  public getQuerystring(_context: T) {
    return null;
  }

  public getHeaders(_context: T): Observable<HeadersInit> {
    return of({}).pipe(
      map((headers) => {
        if (this.contentType) {
          return {
            ...headers,
            'Content-Type': this.contentType,
          };
        }

        return headers;
      }),
      map((headers) => {
        if (this.options.useCookieCsrf) {
          return {
            ...headers,
            'X-CSRFToken': getCookie('csrftoken'),
          };
        }

        return headers;
      })
    );
  }

  public getBody(_context: T): Observable<BodyInit> {
    return of(null);
  }

  public getErrorResponse(response: Response) {
    return throwError({
      message: response.statusText,
      status: response.status,
    });
  }

  public getSuccessResponse(response: Response) {
    return response.json();
  }

  public makeRequest(context: T) {
    return forkJoin([this.getHeaders(context), this.getBody(context)]).pipe(
      concatMap(([headers, body]) => {
        // With FormDta, a Content-Type must be added by the browser in order to include Boundary
        if (body instanceof FormData && 'Content-Type' in headers) {
          delete headers['Content-Type'];
        }

        return this.fetchMethod(this.getUrl(context), {
          method: this.method,
          headers,
          body,
        });
      }),
      mergeMap((response) => {
        if (response.status !== this.expectedStatus) {
          return this.getErrorResponse(response);
        }
        return this.getSuccessResponse(response);
      })
    );
  }
}
