import { Record } from 'immutable';
import {
  IAPIProduct,
  IAPIProductOption,
  IAPIProductVariant,
  IAPProductOptionChoice,
  IAPProductOptionToggle,
  IChoice,
  OptionTypes,
} from './types';

interface IDimension {
  type: string;
  label: string;
  variantValueProp: string;
}

export interface IProductProps {
  id: string;
  type: 'product';

  properties: {
    title?: string;
    slug: string;
    brand: string;
    model?: string;
    variants: string[];
    options: string[];
    dimensions: IDimension[];

    type?: string;
    url?: string;
  };
}

export interface IProductKindProps {
  id: string;
  title: string;

  type?: string;
  url?: string;
}

export class ProductKind extends Record<IProductKindProps>({
  id: '',
  title: '',
  type: 'productKind',
  url: '',
}) {
  public static fromAPI(props: IProductKindProps) {
    const url = `/catalogus/${props.id}`;

    return new ProductKind({
      ...props,
      url,
    });
  }
}

const titleParts = ['brand', 'model', 'type'];

function makeTitle(
  properties: Pick<IAPIProduct, 'title' | 'brand' | 'model' | 'type'>
) {
  const { title } = properties;

  return title
    ? title
    : titleParts
        .filter((part) => properties[part])
        .map((part) => properties[part])
        .join(' ');
}

const defaultVariantProps = {
  id: '',
  type: 'variant',
  properties: {
    price: void 0,
    dimensions: [],
  },
};

export class Variant extends Record(defaultVariantProps) {
  public static fromAPI(
    variant: IAPIProductVariant,
    { dimensions }: { dimensions: IDimension[] }
  ) {
    return new Variant({
      id: variant.id,
      properties: {
        price: variant.priceAmount,
        // Maps the available product dimensions to productVariant values
        dimensions: dimensions.map((d) => ({
          ...d,
          value: variant[d['variantValueProp']] as string,
        })),
      },
    });
  }
}

const defaultToggleOptionsProps = {
  id: '',
  type: 'toggle',
  properties: {
    id: '',
    title: '',
    initialValue: null,
    isRequired: false,
  },
};

export class ToggleOption extends Record(defaultToggleOptionsProps) {
  public static fromAPI(option: IAPProductOptionToggle) {
    return new ToggleOption({
      id: option.id,
      properties: {
        ...option,
      },
    });
  }
}

interface IChoiceOption {
  id: string;
  type: 'choice';
  properties: {
    title: string;
    items: string[];
    initialValue: string;
    isRequired: boolean;
  };
}

const defaultChoiceOptionsProps: IChoiceOption = {
  id: '',
  type: 'choice',
  properties: {
    title: '',
    items: [],
    initialValue: null,
    isRequired: false,
  },
};

export class ChoiceOption extends Record<IChoiceOption>(
  defaultChoiceOptionsProps
) {
  public static fromAPI(option: IAPProductOptionChoice) {
    const { items = [] } = option;

    return new ChoiceOption({
      id: option.id,
      properties: {
        ...option,
        items: items.map(({ id }) => id),
      },
    });
  }
}

const defaultChoiceOptionItemProps = {
  id: '',
  type: 'choiceItem' as const,
  properties: {
    id: '',
    product: null,
    title: '',
    overwrittenPrice: null,
  },
};

export class ChoiceOptionItem extends Record<{
  id: string;
  type: 'choiceItem';
  properties: IChoice;
}>(defaultChoiceOptionItemProps) {
  public static fromAPI(item: IChoice) {
    return new ChoiceOptionItem({
      id: item.id,
      properties: {
        ...item,
      },
    });
  }
}

export const defaultProductProps: IProductProps = {
  id: '',
  type: 'product',

  properties: {
    title: '',
    slug: '',
    url: '',
    brand: '',
    model: '',

    variants: [],
    options: [],
    dimensions: [],
  },
};

export class Product extends Record<IProductProps>(defaultProductProps) {
  protected static dimensionLabels = [
    'dimension1Label',
    'dimension2Label',
    'dimension3Label',
  ];

  public static fromAPI(product: IAPIProduct) {
    const { productVariants = [], options = [], ...rest } = product;

    const dimensions = Product.dimensionLabels
      .filter((prop) => product[prop])
      .map((prop) => ({
        type: prop,
        label: product[prop],
        variantValueProp: prop.replace(/Label/i, ''),
      }));

    const properties = {
      ...rest,
    };

    const title = makeTitle(properties);

    return new Product({
      id: product.id,
      properties: {
        ...properties,
        title,
        variants: productVariants
          .map(({ id } = {} as IAPIProductVariant) => id)
          .filter(Boolean),
        options: options.map(({ id } = {} as OptionTypes) => id),
        dimensions,
      },
    });
  }

  public static empty() {
    return new Product();
  }
}
